.flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

body {
    background-color: #ededed;
    background-image: none;
    font-family: Lato, sans-serif;
    color: #333;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}

html {
    scroll-behavior: smooth;
}

h1 {
    margin-top: 0px;
    margin-bottom: 5px;
    padding-top: 2px;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    text-align: left;
}

h2 {
    margin-top: 10px;
    margin-bottom: 5px;
    padding-top: 2px;
    font-size: 25px;
    line-height: 38px;
    font-weight: 300;
    text-align: left;
}

h3 {
    margin-top: 10px;
    margin-bottom: 8px;
    padding-top: 2px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
    text-align: left;
}

h4 {
    margin-top: 10px;
    margin-bottom: 6px;
    padding-top: 4px;
    padding-bottom: 0px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 700;
    text-align: left;
}

h5 {
    margin-top: 0px;
    margin-bottom: 3px;
    padding-top: 2px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
}

h6 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
}

p {
    display: block;
    max-width: 800px;
    margin: 5px 0px 30px;
    float: none;
    font-size: 16px;
    line-height: 25px;
    text-align: left;
}

a {
    color: #00e;
}

.button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: -100px 0px -1px 30px;
    padding: 14px 14px 14px 13px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.button:hover {
    background-color: #4eb8a9;
}

.button.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.button.secondary:hover {
    background-color: #f5f5f5;
}

.button.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.button.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.button.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.button.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.button.full-width {
    max-width: none;
}

.section-header {
    margin-top: 30px;
    margin-bottom: 60px;
    padding-bottom: 24px;
    border-bottom: 1px solid #ccc;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 40px;
    line-height: 65px;
    font-weight: 300;
    text-align: left;
}

.section-header.light {
    margin-bottom: 45px;
    color: #fff;
}

.section-header.extra-under {
    margin-bottom: 24px;
    padding-top: 0px;
}

.container {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-bottom: 38px;
    margin-left: -125px;
    padding: 50px 80px;
    border-radius: 4px;
    background-color: transparent;
    font-family: Roboto, sans-serif;
    text-align: left;
}

.container.empty {
    width: auto;
    max-width: 1500px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: auto;
    padding: 0px 40px;
    border-top: 2px none #69d2c3;
    background-color: transparent;
    font-family: Roboto, sans-serif;
}

.container.empty._0 {
    padding-right: 0px;
    padding-left: 0px;
}

.container.front-page {
    height: 598px;
    max-width: 1800px;
    margin-top: 121px;
    margin-bottom: -11px;
    padding-top: 100px;
    float: none;
    clear: none;
}

.container.empty {
    width: 100%;
    max-width: 1500px;
    margin-bottom: 0px;
    padding: 0px 40px;
    border-top: 2px none #69d2c3;
    background-color: transparent;
    font-family: Roboto, sans-serif;
}

.buttons-row {
    margin-bottom: 0px;
    padding-top: 4px;
}

._50 {
    display: block;
    width: 80%;
    max-width: 600px;
    margin-bottom: 40px;
    margin-left: 56px;
    padding-right: 20px;
    padding-bottom: 0px;
    float: left;
    font-size: 16px;
}

._50.no-padding {
    padding-right: 0px;
}

.col {
    padding-right: 10px;
    padding-left: 0px;
}

.lable1 {
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: -30px;
    padding-top: 4px;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
}

.text2 {
    width: 80%;
    height: 50px;
    margin-top: 12px;
    margin-bottom: 30px;
    margin-left: -34px;
    padding-top: 12px;
    padding-bottom: 15px;
    padding-left: 20px;
    border-top: 1px none #000;
    border-right: 1px none #000;
    border-left: 1px none #000;
    background-color: #f5f5f5;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
}

.text2:hover {
    border-top-style: none;
    border-right-style: none;
    border-bottom-color: #999;
    border-left-style: none;
    background-color: transparent;
}

.text2:focus {
    padding-bottom: 13px;
    border-style: none none solid;
    border-width: 1px 1px 2px;
    border-color: #fdbb30 #fdbb30 #69d2c3;
}

.text2.last {
    margin-bottom: 20px;
}

.text2.multiline {
    height: 40px;
    min-height: 60px;
    margin-bottom: 30px;
    padding-bottom: 80px;
}

.modals {
    min-height: 600px;
    margin-bottom: 50px;
    padding: 120px 50px 80px;
    background-color: #666;
    color: #999;
}

.modal-box {
    display: block;
    width: 100%;
    min-height: 300px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 40px;
    border-radius: 4px;
    background-color: #fff;
    text-align: left;
}

.modal-box.login {
    height: 100%;
    max-width: 500px;
    min-height: 21%;
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 40px;
}

.checkbox-field {
    margin-bottom: 14px;
    padding-top: 3px;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 700;
}

.checkbox {
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 0px;
    margin-right: 5px;
    margin-bottom: 71px;
    float: left;
}

.navbar {
    position: -webkit-sticky;
    position: sticky;
    z-index: 2000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 60px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.85);
}

.logo {
    width: auto;
    min-height: 60px;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
}

.logo.w--current {
    width: auto;
    margin-right: 0px;
    margin-bottom: 2px;
    padding-top: 28px;
    padding-bottom: 35px;
    background-image: url('../images/Genpool-logo_1.png');
    background-position: 0px 0px;
    background-size: cover;
}

.logo.w--current:hover {
    opacity: 0.8;
}

.select-field {
    height: 50px;
    margin-top: 15px;
    margin-bottom: 30px;
    background-color: #f2f3f3;
    color: #414142;
}

.select-field:focus {
    border: 1px solid #f7446b;
}

.radio-button-field {
    margin-bottom: 24px;
    padding-top: 2px;
    font-family: Roboto, sans-serif;
    font-size: 15px;
}

.radio-button {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    margin-right: 5px;
}

.dropdown-toggle {
    width: 100%;
    margin-bottom: 30px;
    padding: 15px 60px;
    background-color: #f5f5f5;
    box-shadow: 0 1px 0 0 #cfcfd0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    text-align: left;
}

.dropdown-list.w--open {
    margin-top: 0px;
    background-color: #fff;
    box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.1);
}

.dropdown-link {
    background-color: #fff;
    font-family: Roboto, sans-serif;
    color: #414142;
    font-size: 13px;
    text-decoration: none;
}

.dropdown-link:hover {
    background-color: #69d2c3;
    color: #fff;
}

.dropdown-link:active {
    background-color: #3da596;
}

.dropdown-link.w--current {
    color: #3da596;
}

.dropdown-icon {
    color: rgba(65, 65, 66, 0.49);
}

.unordered-list {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 15px;
    padding-top: 0px;
    padding-left: 16px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 16px;
}

.list-item {
    margin-top: 0px;
    margin-bottom: 16px;
    padding-top: 0px;
    padding-bottom: 2px;
    line-height: 30px;
    list-style-type: decimal;
}

.colour-palette-div {
    display: block;
    width: 100px;
    height: 100px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    border-radius: 500px;
    background-color: #69d2c3;
}

.colour-palette-div.secondary {
    background-color: #3da596;
}

.colour-palette-div.tertiary {
    background-color: #fff;
}

.colour-palette-div.tertiary.small {
    background-color: #414142;
}

.colour-palette-div.mint {
    width: 100px;
    height: 100px;
    background-color: #000;
}

.colour-palette-div.salmon {
    background-color: #666;
}

.colour-palette-div.sky {
    background-color: #999;
}

.colour-palette-div.small {
    width: 40px;
    height: 40px;
}

.colour-row {
    margin-bottom: 50px;
    padding-top: 5px;
}

.colour-row.small {
    margin-top: 20px;
    margin-bottom: 30px;
}

.spacer {
    height: 50px;
    margin-bottom: 6px;
}

.tab {
    height: auto;
    margin-right: 5px;
    margin-bottom: 0px;
    padding: 15px 60px;
    float: left;
    border-radius: 5px;
    background-color: #ddd;
    font-family: Roboto, sans-serif;
    color: #777;
    font-size: 15px;
    text-align: center;
}

.tab:hover {
    color: #000;
}

.tab.w--current {
    height: auto;
    margin-bottom: 0px;
    padding: 15px 60px 20px;
    border-bottom: 2px none #f7446b;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    color: #000;
    font-weight: 700;
}

.tab.w--current:hover {
    color: #000;
}

.tabs-content {
    display: block;
    overflow: visible;
    margin: 4px auto 30px;
    padding: 0px;
    border: 1px none #414142;
    border-radius: 9px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.06);
    text-align: center;
}

.list-div {
    width: 100%;
    height: auto;
    margin-bottom: 2px;
    padding: 20px;
    border-bottom: 1px solid #eee;
    background-color: #fff;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    text-align: left;
    text-decoration: none;
}

.list-div:hover {
    background-color: #fff;
    color: #000;
}

.list-div:active {
    background-color: #fff;
}

.list-div.no-padding {
    padding-right: 0px;
    padding-left: 0px;
}

.list-arrow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20px;
    height: 20px;
    margin-top: -34px;
    float: right;
    background-image: url('../images/chevron_1chevron.png');
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.list-heading {
    margin-bottom: 0px;
    margin-left: 15px;
    padding-top: 2px;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    text-align: left;
}

.table-div {
    margin-right: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
    font-family: Roboto, sans-serif;
}

.table-row {
    padding: 15px 0px 20px;
    border-bottom: 1px solid #eee;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
}

.table-row.footer-row {
    margin-top: 2px;
    padding: 0px 10px 0px 20px;
    border-bottom-style: solid;
    border-bottom-color: #eee;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    color: #414142;
    font-size: 14px;
}

.table-row.footer-row {
    padding: 15px 10px 20px 20px;
    border-bottom-style: solid;
    border-bottom-color: #eee;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    color: #414142;
    font-size: 14px;
}

.table-col {
    padding-top: 4px;
    line-height: 25px;
    text-align: left;
}

.list-thumbnail {
    width: 50px;
    height: 50px;
    margin-right: 30px;
    margin-left: 0px;
    float: left;
    border-radius: 100px;
    background-color: #f5f5f5;
    background-image: url('../images/colin-talks.png');
    background-position: 0px 0px;
    background-size: cover;
}

.list-thumbnail.green {
    background-color: #69d2c3;
    background-image: url('../images/Document.svg');
    background-position: 0px 0px;
    background-size: cover;
    background-repeat: repeat;
}

.list-thumbnail.yellow {
    background-color: #fcb26d;
    background-image: url('../images/Headstart-Web-preview.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.list-thumbnail.pink {
    background-color: #fc6586;
    background-image: url('../images/Desktop-Chart.svg');
    background-size: cover;
    background-repeat: no-repeat;
}

.list-thumbnail.blue {
    background-color: transparent;
    background-image: url('../images/Graph-1.svg');
    background-size: cover;
}

.tick {
    width: 15px;
    height: 15px;
    margin-top: -16px;
    float: right;
    background-image: url('../images/grey-tick_1grey-tick.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.tick.delete {
    width: 15px;
    height: 15px;
    margin-top: -19px;
    background-image: url('../images/x-icon_1x-icon.png');
}

.tags {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 20px;
    padding: 5px 10px;
    border: 1px solid #cfcfd0;
    border-radius: 4px;
    background-color: transparent;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    font-family: Roboto, sans-serif;
    color: #4eb8a9;
}

.tags:hover {
    background-color: #414142;
    color: #fff;
}

.calendar {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

._14 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 14.35%;
    height: 8vh;
    min-height: 40px;
    margin-right: -1px;
    padding: 15px 0px;
    float: left;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #f2f3f3;
    background-color: #fff;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
}

._14:hover {
    background-color: #666;
    color: #fff;
}

._14.selected {
    background-color: #69d2c3;
    color: #fff;
}

._14.greyed {
    background-color: #f9f9f9;
    color: #777;
}

.alert-box {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 3px;
    background-color: #ffd6df;
    color: #414142;
}

.alert-box.yellow {
    background-color: #ffdbba;
    color: #d46600;
}

.alert-box.success {
    background-color: rgba(105, 210, 195, 0.3);
    color: #414142;
}

.alert-text {
    max-width: 800px;
    font-family: Roboto, sans-serif;
    color: #777;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
}

.alert-heading {
    margin-bottom: 5px;
    font-family: Roboto, sans-serif;
    color: #080808;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
}

.help-text {
    margin-bottom: 20px;
    font-family: Roboto, sans-serif;
    color: #777;
    font-size: 16px;
    line-height: 25px;
    text-align: left;
}

.help-text.centred {
    font-family: Roboto, sans-serif;
    color: #414142;
    text-align: center;
}

.colour-details-div {
    margin-bottom: 20px;
    padding: 10px 0px 2px;
    background-color: transparent;
    font-family: 'Lineto circular book';
    text-align: center;
}

.delete-box {
    width: 30px;
    height: 30px;
    margin-top: -25px;
    padding-top: 2px;
    float: right;
    border-radius: 4px;
    background-color: #f2f3f3;
    color: #999;
    font-size: 20px;
    text-align: center;
}

.delete-box:hover {
    background-color: #69d2c3;
    color: #fff;
}

.accordion-heading {
    height: auto;
    margin-bottom: 10px;
    padding: 20px 20px 20px 30px;
    border-bottom: 1px solid #e8e8e8;
    background-color: #fff;
    -webkit-transition: all 100ms ease;
    transition: all 100ms ease;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 18px;
    font-weight: 700;
}

.accordion-heading:hover {
    background-color: #69d2c3;
    color: #fff;
}

.plus {
    margin-top: -2px;
    margin-right: 0px;
    padding-top: 0px;
    float: right;
    color: #69d2c3;
    font-size: 35px;
    font-weight: 400;
}

.accordion-icon {
    width: 20px;
    height: 20px;
    float: right;
    font-size: 33px;
}

.accordion-content {
    height: auto;
    margin-top: -10px;
    margin-bottom: 20px;
    padding: 25px 30px 30px;
    background-color: #f9f9f9;
    font-family: 'Lineto circular book';
}

.according-panel {
    margin-bottom: 30px;
}

.form {
    max-width: 600px;
    margin-left: 7px;
    padding-right: 0px;
    text-align: left;
}

.footer-row {
    display: block;
    width: 100%;
    max-width: 1500px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    padding-right: 30px;
    padding-left: 30px;
    text-align: left;
}

.hyperlink {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 24px;
    float: left;
    border-bottom: 1px dotted #69d2c3;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    text-decoration: none;
}

.hyperlink:hover {
    color: #4eb8a9;
}

.hyperlink.light {
    display: inline;
    float: left;
    color: #fff;
}

.paragraph {
    margin-bottom: 24px;
    margin-left: 0px;
    font-family: Roboto, sans-serif;
    color: #777;
    font-size: 16px;
    line-height: 32px;
    font-weight: 300;
    text-align: left;
}

.paragraph.light {
    color: #fff;
}

.tabs-menu {
    width: 100%;
    height: 55px;
    margin-top: 212px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: 1px none #cfcfd0;
}

.footer {
    position: static;
    right: 200px;
    bottom: 0px;
    display: block;
    overflow: visible;
    width: 100%;
    height: 1%;
    min-height: 0px;
    margin-top: 100px;
    margin-bottom: -7px;
    padding: 50px 50px 87px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    border-top: 1px solid #ddd;
    background-color: #666;
    color: #333;
    font-size: 13px;
}

.body {
    overflow: visible;
    padding-bottom: 0px;
    background-color: #f5f5f5;
    background-image: linear-gradient(87deg, #f7f4fa, #f5fffe), url('../images/Topography-bg.png');
    background-position: 0px 0px, 0px 0px;
    background-size: auto, 960px;
    font-family: Roboto, sans-serif;
}

.h1 {
    height: 95%;
    margin-top: 31px;
    margin-bottom: 8px;
    margin-left: -3px;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 40px;
    line-height: 56px;
    font-weight: 300;
}

.h1.light {
    color: #fff;
}

.h2 {
    display: block;
    margin-bottom: 20px;
    float: none;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 28px;
    line-height: 40px;
    font-weight: 300;
    text-align: left;
}

.h2.light {
    color: #fff;
}

.h2.messenger {
    float: left;
}

.h3 {
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 24px;
    line-height: 32px;
    font-weight: 300;
}

.h4 {
    font-family: Roboto, sans-serif;
    color: #000;
    font-weight: 400;
    text-align: left;
}

.h4.light {
    color: #fff;
}

.h4.centred {
    font-weight: 300;
    text-align: center;
}

.left-hand-nav {
    position: fixed;
    z-index: 99;
    display: block;
    overflow: hidden;
    width: 260px;
    min-height: 100vh;
    margin-top: 0px;
    padding-top: 50px;
    padding-right: 10px;
    padding-left: 10px;
    float: left;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: transparent;
}

.main-container {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    float: none;
    background-color: transparent;
}

.main-container.styleguide {
    width: 100vw;
    max-width: 1600px;
    text-align: left;
}

.lhn-links {
    display: inline;
    width: auto;
    min-height: 50px;
    padding: 30px 10px 25px;
    float: left;
    border-style: none none solid;
    border-bottom-width: 4px;
    border-bottom-color: transparent;
    color: #ddd;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    text-decoration: none;
}

.lhn-links:hover {
    padding-top: 27px;
    padding-bottom: 23px;
    padding-left: 10px;
    border-style: solid none none;
    border-top-width: 3px;
    border-top-color: #42e5c9;
    border-bottom-width: 5px;
    border-bottom-color: #69d2c3;
    border-left-width: 3px;
    border-left-color: #69d2c3;
    background-color: transparent;
}

.lhn-links.w--current {
    padding: 27px 25px 25px;
    border-style: solid none none;
    border-top-width: 3px;
    border-top-color: #42e5c9;
    border-bottom-width: 5px;
    border-bottom-color: #69d2c3;
    border-left-width: 5px;
    border-left-color: #69d2c3;
    background-color: transparent;
    background-image: none;
    font-weight: 400;
}

.lhn-links.w--current:hover {
    padding-top: 27px;
    background-color: transparent;
    background-image: none;
    color: #269686;
}

.panel {
    padding: 30px;
    background-color: #fff;
}

.card {
    width: 100%;
    min-height: 360px;
    margin-right: 20px;
    margin-bottom: 30px;
    margin-left: 3px;
    padding: 20px 30px 30px;
    float: none;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0 24px -15px rgba(0, 0, 0, 0.2);
}

.list-description {
    margin-left: 115px;
    color: #777;
    font-size: 12px;
    line-height: 24px;
    font-weight: 300;
}

.hero {
    position: relative;
    display: none;
    width: 100%;
    max-width: 100%;
    min-height: 90vh;
    margin-top: -80px;
    margin-bottom: -120px;
    padding-top: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #414142;
    background-image: url('../images/Genpool-1.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
}

.hero-heading {
    display: block;
    max-width: 900px;
    min-height: auto;
    margin: -97px auto 15px;
    padding-top: auto;
    padding-bottom: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: Bebasneue, sans-serif;
    color: #69d2c3;
    font-size: 70px;
    line-height: 90px;
    font-weight: 100;
    text-align: center;
}

.main-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 64px;
    padding-bottom: 64px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
}

.main-content-infor {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
    width: 100%;
}

.main-content-infor-1{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: center;
    align-items: center;
    margin: 0px 20px;
}

.main-content-infor-2{
    width: 200px;
    border-radius: 11px;
    box-shadow: 0 1px 15px rgba(27,31,35,.15);
    padding: 25px 15px;
    font-size: 18px;
    letter-spacing: 0.1px;
    font-weight: 500;
}

.main-content-infor-2.red{
    color: red;
}

.main-content-infor-3{
    margin-top: 14px;
    font-size: 19px;
    font-weight: 500;
    text-align: center;
}

.main-content-iframe {
    width: 50%;
    height: 481px;
}

.field-label-2 {
    font-size: 15px;
}

.logo-text {
    color: #fff;
}

.logo-text.w--current {
    display: inline;
    padding-top: 25px;
    padding-left: 0px;
    float: left;
    background-color: transparent;
    background-image: url('../images/Genpool-logo_1.png');
    background-position: 0px 0px;
    background-size: 125px;
    font-weight: 700;
}

.icon-div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 19.5%;
    min-height: 120px;
    min-width: auto;
    margin-right: 1px;
    margin-bottom: 1px;
    float: left;
    background-color: #fff;
}

.icon-div.preview {
    width: 100%;
    min-height: 40px;
    margin-bottom: 15px;
}

.icon {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.search {
    width: 25%;
    margin-top: -50px;
    margin-right: 20px;
    float: right;
    font-size: 16px;
}

.search-input {
    width: 100%;
    padding: 20px 15px 20px 25px;
    float: left;
    border: 1px solid #ccc;
    border-radius: 50px;
    background-color: transparent;
    font-family: Roboto, sans-serif;
    font-size: 16px;
}

.search-input:focus {
    border-color: #69d2c3;
}

.search-button {
    margin-top: -52px;
    padding: 11px 20px;
    float: right;
    border-radius: 50px;
    background-color: transparent;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 14px;
}

.search-button.help-button {
    min-height: 60px;
    margin-top: -70px;
    margin-right: 0px;
    padding-right: 40px;
    padding-left: 40px;
    border-radius: 5px;
    background-color: #282829;
    color: #fff;
}

.nav-menu {
    margin-bottom: -19px;
    padding-top: 0px;
    padding-bottom: 0px;
    float: right;
    font-weight: 400;
}

.chat-bubble {
    min-height: 80px;
    margin-bottom: 10px;
    padding: 10px 20px 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f5f5f5;
    font-family: Roboto, sans-serif;
    line-height: 24px;
    text-align: left;
}

.chat-bubble.white {
    border-style: solid;
    border-color: #eee;
    background-color: #fff;
    font-family: Roboto, sans-serif;
}

.chat-meta {
    display: inline-block;
    margin-bottom: 5px;
    float: none;
    color: #999;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
}

.chat-meta.right {
    display: inline-block;
    margin-top: 5px;
    float: right;
    color: #999;
    text-align: right;
}

.calendar-heading {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 8vh;
    min-height: 40px;
    margin-bottom: 0px;
    padding-top: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 2px 2px 1px;
    border-color: #f5f5f5;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}

.segmented-pill {
    width: 33.3%;
    padding-right: 0px;
    padding-left: 0px;
    border: 1px solid #ddd;
    background-color: transparent;
    font-family: Roboto, sans-serif;
    color: #777;
    text-align: center;
}

.segmented-pill.w--current {
    width: 33.3%;
    padding-right: 0px;
    padding-left: 0px;
    float: left;
    border-color: #ddd;
    background-color: transparent;
    color: #000;
    font-weight: 700;
}

.segmented-pill.middle {
    position: static;
    display: block;
    width: 33.3%;
    margin-right: -1px;
    margin-left: -1px;
    padding-right: 0px;
    padding-left: 0px;
    float: left;
    text-align: center;
}

.segmented-pill.middle.w--current {
    color: #000;
}

.segmented-pill.middle._50pc {
    float: left;
}

.segmented-pill._50pc {
    width: 50%;
    float: left;
}

.segmented-pill._50pc.w--current {
    display: block;
    float: left;
}

.seg-pane {
    padding: 20px 0px 30px;
}

.chat-container {
    display: block;
    overflow: scroll;
    width: 100%;
    max-height: 310px;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
    border-bottom: 1px solid #eee;
}

.pill-tag {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 10px;
    padding: 1px 10px 0px;
    border-radius: 30px;
    background-color: #fc6586;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 10px;
    text-decoration: none;
    text-transform: uppercase;
}

.pill-tag.green {
    display: inline-block;
    margin-bottom: 10px;
    padding-top: 1px;
    padding-bottom: 0px;
    background-color: #69d2c3;
    font-family: Roboto, sans-serif;
}

.pill-tag.amber {
    display: inline-block;
    min-width: auto;
    float: none;
    background-color: #fc9b65;
    font-family: Roboto, sans-serif;
}

.pill-tag.grey {
    background-color: #f5f5f5;
    color: #999;
}

.dashboard-cols {
    padding-right: 10px;
    padding-left: 10px;
}

.dashboard-cols.minus {
    padding-right: 20px;
    padding-left: 0px;
}

.dashboard-row {
    display: block;
    width: 100%;
    max-width: 1200px;
    margin-top: 50px;
    margin-right: auto;
    margin-left: 1px;
    font-family: Roboto, sans-serif;
}

.dashboard-row.help {
    display: block;
    margin: -56px auto 30px;
}

.dashboard-row.front-page {
    margin-bottom: 100px;
}

.dashboard-card {
    width: 100%;
    min-height: 450px;
    margin-bottom: 50px;
    padding: 15px 30px 1px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 0 0 #ddd;
}

.dashboard-card.dark {
    background-color: #414142;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(65, 65, 66, 0.9)), to(rgba(65, 65, 66, 0.9))), url('../images/Topography-bg.png');
    background-image: linear-gradient(180deg, rgba(65, 65, 66, 0.9), rgba(65, 65, 66, 0.9)), url('../images/Topography-bg.png');
    background-position: 0px 0px, 0px 0px;
    background-size: auto, 960px;
    color: #fff;
}

.dashboard-card.dark.small {
    background-color: #282829;
}

.dashboard-card.small {
    max-height: auto;
    min-height: 240px;
    margin-bottom: 0px;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    text-align: center;
    text-decoration: none;
}

.dashboard-card.small:hover {
    box-shadow: none;
}

.dashboard-card.small.dark {
    background-color: #414142;
}

.dashboard-card.small.green {
    background-color: #4eb8a9;
}

.dashboard-card.short {
    min-height: 80px;
    margin-bottom: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
}

.dashboard-card.short.padding-under {
    margin-bottom: 16px;
}

.full-container {
    display: block;
    width: 100%;
    max-width: 1500px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 50px 100px;
}

.full-container.help {
    max-width: 1280px;
    padding-top: 107px;
    padding-right: 40px;
    padding-left: 40px;
}

.full-container.help-hero {
    max-width: 1280px;
    padding-top: 80px;
    padding-bottom: 40px;
}

.breadcrumb {
    display: block;
    margin-top: 10px;
    margin-bottom: 50px;
    float: none;
    color: #000;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
}

.pie-chart {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 15vw;
    height: 15vw;
    margin: 25px auto 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 10px solid #eee;
    border-radius: 200px;
    font-size: 23px;
}

.pie-chart.dark {
    border-style: none;
    border-color: #282829;
    background-image: url('../images/donut.svg');
    background-position: 0px 0px;
    background-size: cover;
}

.donut-chart-text {
    margin-top: 25px;
    margin-bottom: 30px;
    color: #777;
    font-size: 32px;
    line-height: 40px;
    font-weight: 300;
}

.donut-chart-text.light {
    color: #fff;
    font-weight: 300;
}

.hyberlink-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 60px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.progress-bar {
    width: 100%;
    height: 8px;
    margin-top: 10px;
    margin-bottom: 25px;
    border-radius: 10px;
    background-color: #f5f5f5;
}

.progress-bar.dark {
    background-color: #282829;
}

.progress-50 {
    width: 50%;
    height: 8px;
    border-radius: 10px;
    background-color: #fcb26d;
    background-image: url('../images/White-crosshatch_1White crosshatch.png');
    background-position: 0px 0px;
    background-size: 56px;
}

.progress-50.red {
    width: 25%;
    background-color: #fc6586;
}

.progress-50.green {
    width: 85%;
    background-color: #69d2c3;
    background-image: url('../images/White-crosshatch_1White crosshatch.png');
    background-position: 0px 0px;
    background-size: 56px;
}

.progress-50.neutral {
    background-color: #69a8d2;
}

.preview-thumb {
    padding-top: 5px;
    padding-bottom: 10px;
}

.meta-text {
    color: #7a7a7a;
    font-weight: 300;
}

.info-icon {
    width: 20px;
    height: 20px;
    margin-top: 7px;
    margin-right: 16px;
    float: right;
    border-radius: 20px;
    background-color: #757575;
    background-image: url('../images/Info-icon.svg');
    background-position: 50% 50%;
    background-size: 2px;
    background-repeat: no-repeat;
}

.list-details {
    margin-left: 56px;
    padding-bottom: 16px;
    float: none;
    border-bottom: 1px solid #e0e0e0;
    line-height: 20px;
    text-align: left;
}

.list-details.no-line {
    border-bottom-style: none;
    color: #777;
    font-size: 14px;
    font-weight: 300;
}

.text-span {
    float: none;
    color: #000;
}

.circle-avatar {
    width: 40px;
    height: 40px;
    margin-top: 5px;
    margin-right: 32px;
    float: left;
    border-radius: 80px;
    background-color: #eee;
}

.circle-avatar.folder {
    background-color: #9e9e9e;
    background-image: url('../images/folder-icon_1folder-icon.png');
    background-position: 0px 0px;
    background-size: 40px;
}

.list-item-2 {
    padding: 16px 0px 0px 16px;
    border-style: none;
    font-family: Roboto, sans-serif;
}

.text-span-2 {
    font-weight: 600;
}

.chat-popup {
    position: fixed;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    display: block;
    width: 320px;
    height: 460px;
    min-height: 360px;
    margin-right: 25px;
    margin-bottom: 90px;
    padding: 15px 30px 30px;
    border-radius: 5px;
    background-color: #f5f5f5;
    background-image: url('../images/Topography-bg.png');
    background-position: 0px 0px;
    background-size: cover;
    box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.2);
}

.chat-text-input {
    width: 100%;
    margin-top: 9px;
    margin-right: -46px;
    margin-left: 0px;
    padding-left: 8px;
    border-style: none;
    background-color: #f5f5f5;
}

.chat-button {
    position: fixed;
    right: 0px;
    bottom: 0px;
    width: 40px;
    height: 40px;
    margin-top: 20px;
    margin-right: 25px;
    margin-bottom: 30px;
    padding-top: 10px;
    border-radius: 10px;
    background-color: #414142;
    font-size: 18px;
    text-decoration: none;
}

.chat-button:hover {
    background-color: #282829;
}

.tiles-container {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-right: 80px;
    padding-left: 80px;
}

.hero-subheading {
    display: block;
    max-width: 650px;
    margin-top: -29px;
    margin-right: auto;
    margin-left: auto;
    color: #f2f3f3;
    font-size: 25px;
    line-height: 55px;
    font-weight: 100;
}

.close-icon {
    width: 16px;
    height: 16px;
    margin-top: 25px;
    float: right;
    background-image: url('../images/close_1.svg');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.form-block {
    background-image: url('../images/close.svg');
    background-position: 0px 0px;
    background-size: 75px;
    background-repeat: no-repeat;
}

.calendar-container {
    margin-bottom: 30px;
}

.profile-photo {
    display: block;
    width: 150px;
    height: 150px;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    border-radius: 200px;
    background-image: url('../images/Tom-Profile.jpg');
    background-position: 50% 50%;
    background-size: cover;
}

.subheading {
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: 12px;
    text-align: left;
}

.help-center-hero {
    min-height: auto;
    margin-top: -80px;
    padding-top: 85px;
    padding-bottom: 40px;
    background-color: #414142;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(65, 65, 66, 0.9)), to(rgba(65, 65, 66, 0.9))), url('../images/Topography-bg.png');
    background-image: linear-gradient(180deg, rgba(65, 65, 66, 0.9), rgba(65, 65, 66, 0.9)), url('../images/Topography-bg.png');
    background-position: 0px 0px, 50% 100%;
    background-size: auto, auto;
}

.help-search {
    margin-top: 35px;
    margin-bottom: 30px;
}

.help-search-input {
    min-height: 60px;
    padding-right: 20px;
    padding-left: 20px;
    float: left;
    border: 1px solid #414142;
    border-radius: 5px;
    background-color: #282829;
    color: #fff;
}

.help-search-input:focus {
    border-color: #69d2c3;
}

.help-search-input::-webkit-input-placeholder {
    color: #fff;
}

.help-search-input:-ms-input-placeholder {
    color: #fff;
}

.help-search-input::-ms-input-placeholder {
    color: #fff;
}

.help-search-input::placeholder {
    color: #fff;
}

.link {
    color: #3da596;
    text-decoration: none;
}

.bold-text {
    font-weight: 500;
}

.list-container {
    margin-left: 260px;
    padding-top: 0px;
    border-bottom: 1px solid #e0e0e0;
}

.nav-link {
    display: block;
    width: 100%;
    height: 40px;
    margin-bottom: 8px;
    padding: 10px 30px 12px;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
    text-align: left;
    text-decoration: none;
}

.nav-link.w--current {
    padding-top: 10px;
    border-radius: 4px;
    background-color: #fff;
    color: #69d2c3;
    font-weight: 500;
    text-align: left;
}

.tabs {
    width: 80vw;
    margin-top: -206px;
    margin-right: 25px;
    margin-left: 25px;
    padding-right: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
}

.tab-pane {
    padding: 25px 30px 30px;
    background-color: #fff;
}

.bar-chart {
    display: block;
    width: 20px;
    height: 40vh;
    margin-right: auto;
    margin-left: auto;
    float: left;
    border: 1px none #ddd;
    border-radius: 15px 15px 0px 0px;
    background-color: #69d2c3;
    background-image: url('../images/White-crosshatch_1White crosshatch.png');
    background-position: 0px 0px;
}

.bar-chart._50pc {
    height: 20vh;
}

.bar-chart._75pc {
    height: 30vh;
}

.bar-chart._25pc {
    height: 10vh;
}

.bar-chart-div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    border-bottom: 1px solid #ddd;
}

.back-to-top {
    position: fixed;
    right: 0px;
    bottom: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    margin-bottom: 25px;
    padding-left: 4px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 40px;
    background-color: #414142;
    font-size: 20px;
    text-decoration: none;
}

.left {
    position: relative;
    float: none;
    text-align: left;
}

.right {
    float: right;
    text-align: right;
}

.curve-graph {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40vh;
    margin-top: 40px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    background-image: url('../images/Graph-bg-lines.svg');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
    -webkit-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
}

.curve-graph-image {
    position: absolute;
    width: 100%;
}

.h5 {
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.block-quote {
    max-width: 800px;
    margin-bottom: 40px;
    border-left-color: #69d2c3;
    font-family: Roboto, sans-serif;
    color: #000;
    line-height: 32px;
    font-weight: 300;
}

.rich-text-block {
    margin-bottom: 10px;
    font-family: Roboto, sans-serif;
    font-weight: 300;
}

.chart-dates {
    margin-top: 15px;
    margin-bottom: 60px;
    color: #999;
}

.short-card-percentage {
    margin-top: 15px;
    float: right;
    color: #777;
    font-size: 30px;
    font-weight: 300;
}

.list-heading-2 {
    color: #000;
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
}

.list-container-2 {
    padding-top: 16px;
    border-bottom: 1px solid #e0e0e0;
}

.list-container-2.extra-padding {
    margin-left: 260px;
    padding-top: 80px;
    background-color: #fff;
}

.list-container-2.extra-padding {
    margin-left: 0px;
    padding-top: 30px;
    border-radius: 5px;
}

.meta-text-2 {
    color: #7a7a7a;
    font-weight: 400;
}

.list-item-3 {
    padding: 16px 0px 0px 32px;
    float: none;
    border-style: none;
    text-align: left;
}

.overline {
    margin-bottom: 8px;
    margin-left: 32px;
    font-size: 12px;
    text-align: left;
    text-transform: uppercase;
}

.time-stamp {
    margin-top: -24px;
    margin-right: 24px;
    float: right;
    color: #7a7a7a;
    font-size: 12px;
    line-height: 24px;
    text-align: right;
}

.list-details-3 {
    margin-left: 56px;
    padding-bottom: 24px;
    float: none;
    border-bottom: 1px solid #e0e0e0;
    line-height: 20px;
}

.apr-div {
    margin-top: 30px;
}

.inbox-left-col {
    padding-left: 0px;
}

.inline-link {
    color: #414142;
    font-weight: 700;
    text-decoration: none;
}

.twitter-link {
    color: #414142;
}

.image {
    margin-top: 0px;
    margin-left: 30px;
    padding-top: 15px;
}

.tabs-menu-2 {
    margin-bottom: 0px;
}

.tabs-content-2 {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.image-2 {
    margin-top: -2px;
    margin-right: 0px;
    padding-bottom: 0px;
    border-radius: 100%;
}

.text-block {
    margin-top: -27px;
    margin-bottom: -35px;
    margin-left: -8px;
    text-align: left;
}

.text-block-2 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

.text-block-3 {
    text-align: left;
}

.text-block-4 {
    text-align: left;
}

.text-block-5 {
    text-align: left;
}

.text-block-6 {
    text-align: center;
}

.list-div-copy {
    width: 100%;
    height: auto;
    margin-bottom: 2px;
    padding: 20px;
    border-bottom: 1px solid #eee;
    background-color: #fff;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    text-align: left;
    text-decoration: none;
}

.list-div-copy:hover {
    background-color: #fff;
    color: #000;
}

.list-div-copy:active {
    background-color: #fff;
}

.list-div-copy.no-padding {
    padding-right: 0px;
    padding-left: 0px;
}

.button-2 {
    width: 100vh;
    margin-top: 0px;
}

.text-block-7 {
    margin-top: -8px;
    color: #42e5c9;
    font-size: 16px;
    font-weight: 500;
}

.text-block-8 {
    margin-top: -8px;
    color: #282829;
    font-size: 15px;
    font-weight: 400;
}

.text-block-9 {
    margin-top: -8px;
    color: #282829;
    font-size: 15px;
    font-weight: 400;
}

.container-2 {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-bottom: 38px;
    margin-left: auto;
    padding: 50px 80px;
    border-radius: 4px;
    background-color: transparent;
    font-family: Roboto, sans-serif;
    text-align: left;
}

.paragraph-2 {
    font-family: Roboto, sans-serif;
    font-style: italic;
}

.list-heading-3 {
    margin-bottom: 0px;
    padding-top: 2px;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
}

.table-div-2 {
    margin-right: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
}

.tabs-content-3 {
    margin-bottom: 30px;
    padding: 30px 30px 20px;
    border: 2px none #f2f3f3;
    background-color: #fff;
}

.list-thumbnail-2 {
    width: 50px;
    height: 50px;
    margin-right: 30px;
    margin-left: 0px;
    float: left;
    border-radius: 100px;
    background-color: #f5f5f5;
    background-image: url('../images/Document.svg');
    background-position: 50% 50%;
    background-size: 40px;
}

.list-description-2 {
    color: #777;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
}

.grid {
    margin-top: 12px;
    grid-template-areas: "Area Meter";
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    background-color: #414142;
}

.text-block-10 {
    margin-top: 51px;
    margin-left: -132px;
    padding-left: 0px;
    font-family: Bebasneue, sans-serif;
    color: #fff;
    font-size: 22px;
}

.heading {
    margin-top: 19px;
    margin-bottom: 123px;
    padding-top: 2px;
}

.heading-2 {
    margin-top: 60px;
}

.grid-copy {
    margin-top: 12px;
    grid-column-gap: 16px;
    grid-template-areas: "Area Meter";
    -ms-grid-columns: 1fr 16px 1fr 16px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: 244px auto;
    grid-template-rows: 244px auto;
    background-color: #414142;
}

.chests {
    margin-top: 32px;
    margin-left: 12px;
}

.premium-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: -74px 0px 9px 19px;
    padding: 15px 14px 12px 13px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.premium-button:hover {
    background-color: #4eb8a9;
}

.premium-button.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.premium-button.secondary:hover {
    background-color: #f5f5f5;
}

.premium-button.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.premium-button.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.premium-button.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.premium-button.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.premium-button.full-width {
    max-width: none;
}

.image-3 {
    margin-top: 32px;
    margin-left: -3px;
}

.button-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: -72px 0px -1px -13px;
    padding: 14px 14px 14px 13px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.button-copy:hover {
    background-color: #4eb8a9;
}

.button-copy.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.button-copy.secondary:hover {
    background-color: #f5f5f5;
}

.button-copy.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.button-copy.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.button-copy.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.button-copy.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.button-copy.full-width {
    max-width: none;
}

.image-4 {
    margin-left: 39px;
}

.image-5 {
    margin-top: 32px;
    margin-left: 15px;
}

.button-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: -71px 0px -1px 7px;
    padding: 14px 14px 14px 13px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.button-copy:hover {
    background-color: #4eb8a9;
}

.button-copy.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.button-copy.secondary:hover {
    background-color: #f5f5f5;
}

.button-copy.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.button-copy.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.button-copy.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.button-copy.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.button-copy.full-width {
    max-width: none;
}

.button-copy-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: -74px 0px -1px 19px;
    padding: 14px 14px 14px 13px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.button-copy-copy:hover {
    background-color: #4eb8a9;
}

.button-copy-copy.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.button-copy-copy.secondary:hover {
    background-color: #f5f5f5;
}

.button-copy-copy.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.button-copy-copy.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.button-copy-copy.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.button-copy-copy.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.button-copy-copy.full-width {
    max-width: none;
}

.super-copy-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: -74px 0px -1px;
    padding: 14px 14px 14px 13px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.super-copy-copy:hover {
    background-color: #4eb8a9;
}

.super-copy-copy.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.super-copy-copy.secondary:hover {
    background-color: #f5f5f5;
}

.super-copy-copy.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.super-copy-copy.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.super-copy-copy.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.super-copy-copy.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.super-copy-copy.full-width {
    max-width: none;
}

.claim-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 70px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: -5px 0px -1px 59px;
    padding: 14px 14px 14px 13px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #282829;
    border-radius: 4px;
    background-color: #3da596;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #282829;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.claim-button:hover {
    background-color: #4eb8a9;
}

.claim-button.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.claim-button.secondary:hover {
    background-color: #f5f5f5;
}

.claim-button.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.claim-button.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.claim-button.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.claim-button.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.claim-button.full-width {
    max-width: none;
}

.claim-button.na {
    margin-left: 20px;
    background-color: #cfcfd0;
    text-align: left;
}

.claim-button.claim {
    margin-left: 20px;
    border-color: #4eb8a9;
    color: #fff;
}

.button-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: -13px 0px 11px 3px;
    padding: 14px 12px 14px 15px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.button-copy:hover {
    background-color: #4eb8a9;
}

.button-copy.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.button-copy.secondary:hover {
    background-color: #f5f5f5;
}

.button-copy.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.button-copy.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.button-copy.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.button-copy.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.button-copy.full-width {
    max-width: none;
}

.link-2 {
    display: block;
    padding-left: 53px;
    color: #3da596;
    font-weight: 400;
    text-decoration: none;
}

.home-table {
    width: 90%;
    height: auto;
    max-width: 880px;
    margin: 0px auto 100px;
    padding: 10px 10px;
    border: 1px none #000;
    border-radius: 5px;
    background-color: #fff;
    text-align: left;
}

.home-table.exchange {
    max-width: 900px;
}

.container-table100 {
    max-width: 1100px;
}

.table100 {
    background-color: #fff;
    overflow-x: auto;
}

.table100 th {
    font-size: 14px;
    font-weight: 500;
    padding-top: 13px;
    padding-bottom: 13px;
}

.table100 td {
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 14px;
}

.row100-head {
    border-bottom: 1px solid #eee;
}

.row100-body {
    border-bottom: 1px solid #eee;
}

.row100-body.dashboard:hover {
    cursor: pointer;
    background-color: #f3f3f3;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
}

.column-text {
    color: #000;
    font-size: 14px;
    text-align: center;
}

.row-addition-text {
    width: 100%;
    color: #000;
    font-size: 14px;
    text-align: justify;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.row-addition-vote {
    padding: 5px 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    justify-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    color: #000;
    font-size: 14px;
}

.row-addition-name{
    color: #000;
    font-size: 14px;
}

.row-addition-text.title{
    font-weight: 800;
    margin-bottom: 7px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.row-addition-text.paragraph{
    padding: 1px 10px;
    text-align: left;
    white-space: pre-line;
    display: flex;
    flex-direction: column;
}

.paragraph-text{
    width: 100%;
    color: #000;
    font-size: 14px;
    text-align: justify;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    margin-bottom: 0px;
}

.row-addition-text.paragraph.apr{
    color: #2cbae0;
    font-weight: 700;
}

.row-addition-text1 {
    width: 50%;
    color: #000;
    font-size: 14px;
    margin-right: 5px;
    text-align: right;
}
.row-addition-text2 {
    width: 40%;
    color: #000;
    font-size: 14px;
    text-align: left;
    margin-left: 5px;
}
.row-addition-text2.link {
    color: #2cbae0;
}



.column-text.name {
    text-align: center;
}

.column-text.number {
    text-align: right;
}

.column-text.link {
    text-align: right;
}

.col-text {
    width: 100%;
    text-align: center;
}

.column2 {
    width: 80px;
}

.row-addition{
    animation: fadeIn 0.3s linear;
}

.row-addition.hide{
    animation: fadeIn 0.3s linear;
    display: none;
}

.row-addition1{
    width: 260px;
    max-width: 260px;
}

.row-addition2{
    width: 260px;
    max-width: 260px;
}

.row-addition3{
    width: 360px;
    max-width: 360px;
}

.row-addition4{
    width: 180px;
    max-width: 180px;
}

.column3 {
    width: 180px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: start;
    -webkit-justify-content: center;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: flex-end;
    -ms-flex-align: center;
    align-items: flex-end;
    text-align: left;
}

.column3.exchange {
    width: 240px;
}

.column-text.name {
    margin-right: 0px;
    margin-left: 6px;
    color: #000;
    font-size: 14px;
}

.column4 {
    width: 160px;
    margin-bottom: 30px;
}

.column4.exchange {
    width: 160px;
}

.column5 {
    width: 120px;
    position: relative;
}

.column5.exchange {
    width: 150px;
}

.column6 {
    width: 120px;
    position: relative;
}

.column6.exchange {
    width: 150px;
}

.column7 {
    width: 120px;
    position: relative;
}

.column7.exchange {
    width: 150px;
}

.column8 {
    width: 120px;
    color: #2cbae0;
    position: relative;
}

.column9 {
    width: 180px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.column9.exchange {
    width: 100px;
    user-select: none;
}

.column9.exchange:hover{
    cursor: pointer;
}

.img-info-topup {
    width: 13px;
    margin-left: 2px;
    margin-bottom: 2px;
}

.dashboard-table {
    width: 100%;
    height: auto;
    margin: 0px auto 10px;
    padding: 10px 10px;
    border: 1px none #000;
    border-radius: 5px;
    background-color: #fff;
    text-align: left;
}

.col-dashProxy-text {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
}

.col-dashBp-text {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
}

.column2-dashProxy {
    width: 100px;
}

.column3-dashProxy {
    width: 230px;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: start;
    -webkit-justify-content: center;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: flex-end;
    -ms-flex-align: center;
    align-items: flex-end;
    text-align: center;
}

.column4-dashProxy {
    width: 200px;
}

.column6-dashProxy {
    width: 100px;
}

.column7-dashProxy {
    width: 150px;
}

.column2-dashBp {
    width: 80px;
}

.column3-dashBp {
    width: 200px;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: start;
    -webkit-justify-content: center;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: flex-end;
    -ms-flex-align: center;
    align-items: flex-end;
    text-align: center;
}

.column4-dashBp {
    width: 180px;

}

.column5-dashBp {
    width: 100px;

}

.column6-dashBp {
    width: 100px;
}

.column7-dashBp {
    width: 280px;
    display: flex;
    justify-content: flex-start;
}

.row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 54px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #eee;
}

._1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 6%;
    height: 100%;
    margin-left: 0px;
    padding-left: 10px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.bp-table-continer-home {
    width: 100%;
    height: auto;
    max-width: 800px;
    margin: 0px auto 100px;
    padding: 10px 10px;
    border: 1px none #000;
    border-radius: 5px;
    background-color: #fff;
    text-align: left;
}

.bp-table-home {
    max-width: 800px;
}

.bp-table {
    background-color: #fff;
    overflow-x: auto;
}

.bp-table-head {
    border-bottom: 1px solid #eee;
}

.bp-table-body {
    border-bottom: 1px solid #eee;
}

.bp-table th {
    font-size: 14px;
    font-weight: 500;
    padding-top: 13px;
    padding-bottom: 13px;
}

.bp-table td {
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 14px;
}

.bp-column-1 {
    width: 150px;
    min-width: 120px;
}

.bp-column-text-1 {
    text-align: center;
}

.bp-column-2 {
    width: 300px;
    min-width: 200px;
}

.bp-column-text-2 {
    text-align: center;
}

.bp-column-text-2.daily-amount {
    padding-left: 20px;
    padding-right: 20px;
}

.bp-column-3 {
    width: 300px;
    min-width: 140px;
}

.bp-column-text-3 {
    text-align: center;
    padding: 4px 14px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #01010f;
    text-decoration: none;
    width: 50%;
    cursor: pointer;
    width: 150px;
}

.text-block-8-copy {
    margin-top: -8px;
    color: #282829;
    font-weight: 400;
}

.heading1 {
    width: auto;
    margin-top: 0px;
    margin-left: -4px;
    padding-left: 0px;
    color: #282829;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.heading1.highlight {
    margin-left: 0px;
    color: #2cbae0;
}

.heading1.highlight-copy {
    margin-left: 0px;
    color: #2cbae0;
}

.body1 {
    margin-right: 0px;
    margin-left: 6px;
    color: #000;
}

.body1.a {
    margin-left: 8px;
    font-size: 14px;
}

.body1.type {
    font-size: 12px;
    font-weight: 700;
}

.body1.c {
    margin-left: -5px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}


.body1.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.body1.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.body1.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.body1.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

._2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 17%;
    height: 100%;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

._3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 8%;
    height: 101%;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.div-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 30px;
    margin-left: 16px;
    padding-right: 8px;
    padding-left: 5px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 0.5px solid #000;
    border-radius: 6px;
}

._4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 6%;
    height: 101%;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

._3-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 10%;
    height: 101%;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

._5 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 10%;
    height: 101%;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

._6 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 10%;
    height: 101%;
    margin-left: 0px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

._7 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 15%;
    height: 101%;
    margin-left: 0px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

._8 {
    display: none;
    width: 21%;
    height: 101%;
    margin-left: 0px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

._8.buttonvote {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 14%;
    height: 100%;
}

.rowv2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 65px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #eee;
    text-decoration: none;
}

.rowv2:hover {
    background-color: #fbf9ff;
}

.div-block-2 {
    width: 80%;
    height: 300px;
    margin-top: 37px;
    margin-right: auto;
    margin-left: auto;
}

.table1 {
    display: block;
    width: 90%;
    height: auto;
    max-width: 1200px;
    margin-top: 115px;
    margin-right: 5%;
    margin-left: 5%;
    padding: 38px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.09);
}

.div-block-3 {
    width: 50%;
    height: 100%;
    padding-right: 12px;
    padding-left: 12px;
    float: left;
}

.heading-3 {
    font-size: 22px;
    font-weight: 500;
}

.div-block-4 {
    width: 90%;
    height: 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
}

.link-3 {
    color: #42e5c9;
}

.link-4 {
    color: #42e5c9;
}

.link-5 {
    color: #2cbae0;
}

.checkbox-label {
    margin-top: -2px;
}

.text-block-11 {
    margin-top: 22px;
    font-style: italic;
}

.text-block-12 {
    margin-top: 13px;
    font-family: Roboto, sans-serif;
    font-style: italic;
}

.refund-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: 29px 0px -1px 30px;
    padding: 14px 14px 14px 13px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.refund-button:hover {
    background-color: #4eb8a9;
}

.refund-button.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.refund-button.secondary:hover {
    background-color: #f5f5f5;
}

.refund-button.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.refund-button.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.refund-button.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.refund-button.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.refund-button.full-width {
    max-width: none;
}

.fund {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: 11px 0px 36px 49px;
    padding: 11px 14px 14px 13px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.fund:hover {
    background-color: #4eb8a9;
}

.fund.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.fund.secondary:hover {
    background-color: #f5f5f5;
}

.fund.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.fund.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.fund.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.fund.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.fund.full-width {
    max-width: none;
}

.vote-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 30px;
    margin-left: 27px;
    padding-right: 8px;
    padding-left: 5px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 0.5px solid #000;
    border-radius: 6px;
}

.tabs-menu-bp {
    margin-left: 0px;
}

.paragraph-3 {
    height: 96%;
}

.button-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: 1px 0px -1px 30px;
    padding: 12px 14px 14px 13px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.button-copy:hover {
    background-color: #4eb8a9;
}

.button-copy.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.button-copy.secondary:hover {
    background-color: #f5f5f5;
}

.button-copy.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.button-copy.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.button-copy.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.button-copy.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.button-copy.full-width {
    max-width: none;
}

._50-copy {
    display: block;
    width: 50%;
    margin-bottom: 40px;
    margin-left: 56px;
    padding-right: 20px;
    padding-bottom: 0px;
    float: left;
    font-size: 16px;
}

._50-copy.no-padding {
    padding-right: 0px;
}

.modal-box-copy {
    display: block;
    width: 100%;
    min-height: 300px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 40px;
    border-radius: 4px;
    background-color: #fff;
    text-align: left;
}

.modal-box-copy.login {
    height: 100%;
    max-width: 500px;
    min-height: 21%;
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 40px;
}

.modal-box-copy.login-copy {
    height: 750px;
    max-width: 600px;
    min-height: 100%;
    margin-top: 30px;
    margin-left: 29px;
    padding-top: 30px;
    padding-bottom: 40px;
}

.heading-4 {
    margin-top: -47px;
    margin-left: 98px;
    padding-top: 0px;
}

.fund-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: -41px 0px 36px 277px;
    padding: 11px 14px 14px 7px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.fund-copy:hover {
    background-color: #4eb8a9;
}

.fund-copy.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.fund-copy.secondary:hover {
    background-color: #f5f5f5;
}

.fund-copy.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.fund-copy.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.fund-copy.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.fund-copy.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.fund-copy.full-width {
    max-width: none;
}

.fund-copy-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: -13px 0px -64px 277px;
    padding: 11px 14px 14px 7px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.fund-copy-copy:hover {
    background-color: #4eb8a9;
}

.fund-copy-copy.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.fund-copy-copy.secondary:hover {
    background-color: #f5f5f5;
}

.fund-copy-copy.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.fund-copy-copy.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.fund-copy-copy.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.fund-copy-copy.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.fund-copy-copy.full-width {
    max-width: none;
}

.field-label-copy {
    width: 60px;
    margin-top: -72px;
    margin-bottom: 134px;
    margin-left: -65px;
    padding-top: 4px;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
}

.text-field-copy {
    width: 200px;
    height: 50px;
    margin-top: -40px;
    margin-bottom: 30px;
    margin-left: 34px;
    padding-top: 12px;
    padding-bottom: 15px;
    padding-left: 20px;
    border-top: 1px none #000;
    border-right: 1px none #000;
    border-left: 1px none #000;
    background-color: #f5f5f5;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
}

.text-field-copy:hover {
    border-top-style: none;
    border-right-style: none;
    border-bottom-color: #999;
    border-left-style: none;
    background-color: transparent;
}

.text-field-copy:focus {
    padding-bottom: 13px;
    border-style: none none solid;
    border-width: 1px 1px 2px;
    border-color: #fdbb30 #fdbb30 #69d2c3;
}

.text-field-copy.last {
    margin-bottom: 20px;
}

.text-field-copy.multiline {
    height: 40px;
    min-height: 60px;
    margin-bottom: 30px;
    padding-bottom: 80px;
}

.text-field-copy {
    width: 200px;
    height: 50px;
    margin-top: -118px;
    margin-bottom: 30px;
    margin-left: 34px;
    padding-top: 12px;
    padding-bottom: 15px;
    padding-left: 20px;
    border-top: 1px none #000;
    border-right: 1px none #000;
    border-left: 1px none #000;
    background-color: #f5f5f5;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
}

.text-field-copy:hover {
    border-top-style: none;
    border-right-style: none;
    border-bottom-color: #999;
    border-left-style: none;
    background-color: transparent;
}

.text-field-copy:focus {
    padding-bottom: 13px;
    border-style: none none solid;
    border-width: 1px 1px 2px;
    border-color: #fdbb30 #fdbb30 #69d2c3;
}

.text-field-copy.last {
    margin-bottom: 20px;
}

.text-field-copy.multiline {
    height: 40px;
    min-height: 60px;
    margin-bottom: 30px;
    padding-bottom: 80px;
}

.field-label-copy-copy {
    width: 60px;
    margin-top: -99px;
    margin-bottom: 134px;
    margin-left: -59px;
    padding-top: 4px;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
}

.text-field-copy-copy {
    width: 200px;
    height: 50px;
    margin-top: -190px;
    margin-bottom: 30px;
    margin-left: 34px;
    padding-top: 12px;
    padding-bottom: 15px;
    padding-left: 20px;
    border-top: 1px none #000;
    border-right: 1px none #000;
    border-left: 1px none #000;
    background-color: #f5f5f5;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
}

.text-field-copy-copy:hover {
    border-top-style: none;
    border-right-style: none;
    border-bottom-color: #999;
    border-left-style: none;
    background-color: transparent;
}

.text-field-copy-copy:focus {
    padding-bottom: 13px;
    border-style: none none solid;
    border-width: 1px 1px 2px;
    border-color: #fdbb30 #fdbb30 #69d2c3;
}

.text-field-copy-copy.last {
    margin-bottom: 20px;
}

.text-field-copy-copy.multiline {
    height: 40px;
    min-height: 60px;
    margin-bottom: 30px;
    padding-bottom: 80px;
}

.update1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: -177px 0px 37px 332px;
    padding: 12px 14px 14px 10px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.update1:hover {
    background-color: #4eb8a9;
}

.update1.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.update1.secondary:hover {
    background-color: #f5f5f5;
}

.update1.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.update1.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.update1.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.update1.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.update1.full-width {
    max-width: none;
}

.form-copy {
    max-width: 600px;
    padding-right: 0px;
    text-align: left;
}

.field-label-copy-copy-copy {
    width: 200px;
    margin-top: 34px;
    margin-bottom: 134px;
    margin-left: -65px;
    padding-top: 4px;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}

.text-field-copy-copy-copy {
    width: 170px;
    height: 50px;
    margin-top: -80px;
    margin-bottom: 30px;
    margin-left: -61px;
    padding-top: 12px;
    padding-bottom: 15px;
    padding-left: 20px;
    border-top: 1px none #000;
    border-right: 1px none #000;
    border-left: 1px none #000;
    background-color: #f5f5f5;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
}

.text-field-copy-copy-copy:hover {
    border-top-style: none;
    border-right-style: none;
    border-bottom-color: #999;
    border-left-style: none;
    background-color: transparent;
}

.text-field-copy-copy-copy:focus {
    padding-bottom: 13px;
    border-style: none none solid;
    border-width: 1px 1px 2px;
    border-color: #fdbb30 #fdbb30 #69d2c3;
}

.text-field-copy-copy-copy.last {
    margin-bottom: 20px;
}

.text-field-copy-copy-copy.multiline {
    height: 40px;
    min-height: 60px;
    margin-bottom: 30px;
    padding-bottom: 80px;
}

.field-label-copy-copy-copy-copy {
    width: 200px;
    margin-top: 34px;
    margin-bottom: 134px;
    margin-left: -65px;
    padding-top: 4px;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}

.updateproxy {
    width: 500px;
    margin-top: -119px;
    margin-bottom: 134px;
    margin-left: -18px;
    padding-top: 4px;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
}

.text-field-copy-copy-copy-copy {
    width: 200px;
    height: 50px;
    margin-top: -117px;
    margin-bottom: 30px;
    margin-left: 228px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    border-top: 1px none #000;
    border-right: 1px none #000;
    border-left: 1px none #000;
    background-color: #f5f5f5;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
}

.text-field-copy-copy-copy-copy:hover {
    border-top-style: none;
    border-right-style: none;
    border-bottom-color: #999;
    border-left-style: none;
    background-color: transparent;
}

.text-field-copy-copy-copy-copy:focus {
    padding-bottom: 13px;
    border-style: none none solid;
    border-width: 1px 1px 2px;
    border-color: #fdbb30 #fdbb30 #69d2c3;
}

.text-field-copy-copy-copy-copy.last {
    margin-bottom: 20px;
}

.text-field-copy-copy-copy-copy.multiline {
    height: 40px;
    min-height: 60px;
    margin-bottom: 30px;
    padding-bottom: 80px;
}

.text-field-copy-copy-copy-copy-copy {
    width: 170px;
    height: 50px;
    margin-top: -124px;
    margin-bottom: 30px;
    margin-left: 148px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    border-top: 1px none #000;
    border-right: 1px none #000;
    border-left: 1px none #000;
    background-color: #f5f5f5;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
}

.text-field-copy-copy-copy-copy-copy:hover {
    border-top-style: none;
    border-right-style: none;
    border-bottom-color: #999;
    border-left-style: none;
    background-color: transparent;
}

.text-field-copy-copy-copy-copy-copy:focus {
    padding-bottom: 13px;
    border-style: none none solid;
    border-width: 1px 1px 2px;
    border-color: #fdbb30 #fdbb30 #69d2c3;
}

.text-field-copy-copy-copy-copy-copy.last {
    margin-bottom: 20px;
}

.text-field-copy-copy-copy-copy-copy.multiline {
    height: 40px;
    min-height: 60px;
    margin-bottom: 30px;
    padding-bottom: 80px;
}

.text-field-copy-copy-copy-copy-copy-copy {
    width: 170px;
    height: 50px;
    margin-top: -80px;
    margin-bottom: 30px;
    margin-left: 148px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    border-top: 1px none #000;
    border-right: 1px none #000;
    border-left: 1px none #000;
    background-color: #f5f5f5;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
}

.text-field-copy-copy-copy-copy-copy-copy:hover {
    border-top-style: none;
    border-right-style: none;
    border-bottom-color: #999;
    border-left-style: none;
    background-color: transparent;
}

.text-field-copy-copy-copy-copy-copy-copy:focus {
    padding-bottom: 13px;
    border-style: none none solid;
    border-width: 1px 1px 2px;
    border-color: #fdbb30 #fdbb30 #69d2c3;
}

.text-field-copy-copy-copy-copy-copy-copy.last {
    margin-bottom: 20px;
}

.text-field-copy-copy-copy-copy-copy-copy.multiline {
    height: 40px;
    min-height: 60px;
    margin-bottom: 30px;
    padding-bottom: 80px;
}

.update-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: 44px 0px 37px 276px;
    padding: 12px 14px 14px 10px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.update-copy:hover {
    background-color: #4eb8a9;
}

.update-copy.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.update-copy.secondary:hover {
    background-color: #f5f5f5;
}

.update-copy.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.update-copy.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.update-copy.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.update-copy.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.update-copy.full-width {
    max-width: none;
}

.update1-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: -46px 0px 37px 332px;
    padding: 12px 14px 14px 10px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.update1-copy:hover {
    background-color: #4eb8a9;
}

.update1-copy.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.update1-copy.secondary:hover {
    background-color: #f5f5f5;
}

.update1-copy.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.update1-copy.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.update1-copy.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.update1-copy.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.update1-copy.full-width {
    max-width: none;
}

.text2-copy {
    width: 200px;
    height: 50px;
    margin-top: -113px;
    margin-bottom: 30px;
    margin-left: 34px;
    padding-top: 12px;
    padding-bottom: 15px;
    padding-left: 20px;
    border-top: 1px none #000;
    border-right: 1px none #000;
    border-left: 1px none #000;
    background-color: #f5f5f5;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
}

.text2-copy:hover {
    border-top-style: none;
    border-right-style: none;
    border-bottom-color: #999;
    border-left-style: none;
    background-color: transparent;
}

.text2-copy:focus {
    padding-bottom: 13px;
    border-style: none none solid;
    border-width: 1px 1px 2px;
    border-color: #fdbb30 #fdbb30 #69d2c3;
}

.text2-copy.last {
    margin-bottom: 20px;
}

.text2-copy.multiline {
    height: 40px;
    min-height: 60px;
    margin-bottom: 30px;
    padding-bottom: 80px;
}

.text2-copy {
    width: 200px;
    height: 50px;
    margin-top: -79px;
    margin-bottom: 30px;
    margin-left: 34px;
    padding-top: 12px;
    padding-bottom: 15px;
    padding-left: 20px;
    border-top: 1px none #000;
    border-right: 1px none #000;
    border-left: 1px none #000;
    background-color: #f5f5f5;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
}

.text2-copy:hover {
    border-top-style: none;
    border-right-style: none;
    border-bottom-color: #999;
    border-left-style: none;
    background-color: transparent;
}

.text2-copy:focus {
    padding-bottom: 13px;
    border-style: none none solid;
    border-width: 1px 1px 2px;
    border-color: #fdbb30 #fdbb30 #69d2c3;
}

.text2-copy.last {
    margin-bottom: 20px;
}

.text2-copy.multiline {
    height: 40px;
    min-height: 60px;
    margin-bottom: 30px;
    padding-bottom: 80px;
}

.text2-copy-copy {
    width: 200px;
    height: 50px;
    margin-top: -79px;
    margin-bottom: 30px;
    margin-left: 34px;
    padding-top: 12px;
    padding-bottom: 15px;
    padding-left: 20px;
    border-top: 1px none #000;
    border-right: 1px none #000;
    border-left: 1px none #000;
    background-color: #f5f5f5;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
}

.text2-copy-copy:hover {
    border-top-style: none;
    border-right-style: none;
    border-bottom-color: #999;
    border-left-style: none;
    background-color: transparent;
}

.text2-copy-copy:focus {
    padding-bottom: 13px;
    border-style: none none solid;
    border-width: 1px 1px 2px;
    border-color: #fdbb30 #fdbb30 #69d2c3;
}

.text2-copy-copy.last {
    margin-bottom: 20px;
}

.text2-copy-copy.multiline {
    height: 40px;
    min-height: 60px;
    margin-bottom: 30px;
    padding-bottom: 80px;
}

.but {
    width: 200px;
    height: 50px;
    margin-top: -79px;
    margin-bottom: 30px;
    margin-left: 34px;
    padding-top: 12px;
    padding-bottom: 15px;
    padding-left: 20px;
    border-top: 1px none #000;
    border-right: 1px none #000;
    border-left: 1px none #000;
    background-color: #f5f5f5;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
}

.but:hover {
    border-top-style: none;
    border-right-style: none;
    border-bottom-color: #999;
    border-left-style: none;
    background-color: transparent;
}

.but:focus {
    padding-bottom: 13px;
    border-style: none none solid;
    border-width: 1px 1px 2px;
    border-color: #fdbb30 #fdbb30 #69d2c3;
}

.but.last {
    margin-bottom: 20px;
}

.but.multiline {
    height: 40px;
    min-height: 60px;
    margin-bottom: 30px;
    padding-bottom: 80px;
}

.lable1-copy {
    margin-top: 42px;
    margin-bottom: 10px;
    margin-left: -65px;
    padding-top: 4px;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
}

.lable1-copy {
    margin-top: 42px;
    margin-bottom: 10px;
    margin-left: -65px;
    padding-top: 4px;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
}

.lable1-copy-copy {
    margin-top: 42px;
    margin-bottom: 10px;
    margin-left: -65px;
    padding-top: 4px;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
}

.lable1-copy {
    margin-top: 42px;
    margin-bottom: 10px;
    margin-left: -65px;
    padding-top: 4px;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
}

.field-label-3 {
    margin-left: -47px;
}

.button-copy-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100px;
    height: 40px;
    max-width: 300px;
    min-height: 0px;
    min-width: 0px;
    margin: 27px 0px 11px -26px;
    padding: 14px 12px 14px 15px;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #69d2c3;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.button-copy-copy:hover {
    background-color: #4eb8a9;
}

.button-copy-copy.secondary {
    padding-top: 13px;
    padding-bottom: 13px;
    float: none;
    border: 2px solid #69d2c3;
    background-color: #fff;
    color: #69d2c3;
}

.button-copy-copy.secondary:hover {
    background-color: #f5f5f5;
}

.button-copy-copy.tertiary {
    padding: 13px 50px;
    float: none;
    border: 2px solid #ccc;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    transition: background-color 300ms ease, border-color 300ms ease, color 500ms ease;
    color: #999;
    text-transform: uppercase;
}

.button-copy-copy.tertiary:hover {
    border-color: #69d2c3;
    color: #69d2c3;
}

.button-copy-copy.disabled {
    background-color: #ddd;
    color: rgba(65, 65, 66, 0.49);
}

.button-copy-copy.preview {
    margin-top: 16px;
    margin-bottom: 31px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #69d2c3;
    background-color: transparent;
    color: #3da596;
    font-size: 14px;
}

.button-copy-copy.full-width {
    max-width: none;
}

.italic-text {
    margin-left: -46px;
    padding-left: 0px;
}

.text-block-12-copy {
    margin-top: 13px;
    margin-left: -30px;
    font-family: Roboto, sans-serif;
    font-style: italic;
}

.section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 600px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.div-block-5 {
    width: 450px;
    margin-right: 10px;
    margin-left: 10px;
    text-align: left;
    cursor: pointer;
}

.div-block-6 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 497px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
}

.heading-5 {
    width: 90%;
    color: #222129;
    font-size: 50px;
    line-height: 62px;
    font-weight: 700;
}

.heading-5-pickreward{
    color: #222129;
    line-height: 50px;
    font-weight: 700;
    font-size: 30px;
}

.heading-5.sub {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 15px;
    font-size: 21px;
    font-weight: 500;
    text-align: center;
}

.heading-5.sub2 {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 7px;
    font-size: 21px;
    line-height: 35px;
    font-weight: 500;
    text-align: center;
}

.heading-5.sub2.nay {
    margin-top: 0px;
    margin-bottom: 15px;
    text-align: left;
}

.heading-5.sub2.left {
    text-align: left;
}

.heading-5._2 {
    width: 400px;
}

.heading-5._22 {
    width: 50%;
    margin-top: -62px;
    margin-bottom: 0px;
    /* margin-left: -70px; */
    font-size: 30px;
}

.heading-5._22.naay {
    margin-top: -73px;
    margin-bottom: -22px;
    margin-left: -170px;
    font-size: 25px;
}

.heading-5.bottom {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 60px;
    background-color: #fff;
    font-size: 21px;
    line-height: 40px;
    font-weight: 500;
    text-align: center;
}

.heading-5._22-copy {
    width: 500px;
}

.heading-5.sub2 {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 7px;
    font-size: 21px;
    line-height: 35px;
    font-weight: 500;
    text-align: left;
}

.text-block-13 {
    margin-top: 13px;
    color: #8a8bac;
    font-size: 17px;
    line-height: 29px;
    font-weight: 400;
    text-align: left;
}

.text-block-13.centre {
    width: 608px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 20px;
    padding-bottom: 60px;
    text-align: center;
}

.text-block-13.centre.correct {
    width: 400px;
    padding-top: 0px;
}

.text-block-13.info {
    width: 100%;
    margin-top: -28px;
    margin-left: 2px;
    color: #667;
    font-size: 15px;
    line-height: 24px;
    font-weight: 300;
    text-align: center;
}

.text-block-13.info.left {
    width: 100%;
    margin-top: -6px;
    margin-left: -4px;
    text-align: left;
}

.text-block-13.info.left.bold {
    font-weight: 400;
}

.text-block-13.sub3 {
    margin-top: 31px;
    color: #222129;
    font-weight: 500;
}

.text-block-13.info {
    margin-top: 0px;
    color: #667;
    font-size: 15px;
    line-height: 24px;
    font-weight: 300;
}

.text-span-3 {
    color: #4a4b6b;
    font-weight: 500;
}

.button-3 {
    margin-top: 26px;
    padding: 20px 30px;
    border-radius: 11px;
    background-color: #2cbae0;
    font-size: 16px;
    letter-spacing: 0.7px;
}

.button-3-copy {
    padding: 20px 30px;
    border-radius: 11px;
    background-color: #2cbae0;
    font-size: 16px;
    letter-spacing: 0.7px;
}

.button-4 {
    padding: 10px 15px;
    border-radius: 8px;
    background-color: #2cbae0;
    font-size: 16px;
    letter-spacing: 0.7px;
}

.image-bpInter-1 {
    width: 20px;
    margin-right: auto;
    margin-left: 40px;
}

.image-bpInter-2 {
    width: 20px;
    margin-right: auto;
    margin-left: 60px;
}

.image-bpInter-3 {
    width: 20px;
    margin-right: auto;
    margin-left: 20px;
}

.image-bpInter-4 {
    width: 20px;
    margin-right: auto;
    margin-left: 20px;
}

.setBtn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.image-main-1 {
    width: 20px;
    margin-left: 5px;
}

.image-dash-1 {
    width: 20px;
    margin-left: 5px;
}

.image-refProxy-1 {
    width: 20px;
    margin-left: 18px;
}

.image-bpInternal-1 {
    width: 20px;
    margin-left: 18px;
}

.image-bpInternal-2 {
    width: 20px;
    margin-left: 13px;
}

.image-bpInternal-3 {
    width: 20px;
    margin-left: 4px;
}

.proxyInterBtn-1 {
    width: 130px;
    height: 41px;
}

.proxyInterBtn-2 {
    width: 170px;
    height: 41px;
}

.proxyInterBtn-3 {
    width: 100px;
    height: 51px;
}

.proxyInterBtn-4 {
    width: 100px;
    height: 51px;
}

.regProxyBtn-1 {
    width: 95px;
    height: 51px;
}

.button-referral-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    padding: 10px 10px;
    border-radius: 11px;
    background-color: #2cbae0;
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.7px;
    user-select: none;
    margin-left: 14px;
    width: 95px;
    height: 40px;
    color: white;
}

.button-referral-btn:hover{
    cursor: pointer;
}

.bpInterBtn-1 {
    width: 100px;
    height: 51px;
}

.bpInterBtn-2 {
    width: 85px;
    height: 51px;
}

.button-4.vote {
    width: 130px;
}

.div-block-7 {
    width: 43px;
    margin: 15px;
}

.div-block-8 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 0px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
}

.div-block-9 {
    background-color: #fff;
}

.div-block-10 {
    background-color: #fff;
}

.bold-text-2 {
    color: #5c5c8a;
}

.div-block-11 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 70px;
    padding-bottom: 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 1px solid #ddd1d1;
}

.text-field {
    width: 100%;
    height: 36px;
    margin-bottom: 0px;
    margin-left: -12px;
    padding-left: 11px;
    border-style: solid;
    border-width: 1px;
    border-color: #d8e7e5;
    border-radius: 9px;
    color: #222129;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
}

.text-field.referral-link{
    width: 80%;
    margin: 0px 0px;
}

.text-field.main-page{
    width: 100%;
    margin: 0px 0px;
}

.text-field::-webkit-input-placeholder {
    color: #222129;
    font-weight: 300;
}

.text-field:-ms-input-placeholder {
    color: #222129;
    font-weight: 300;
}

.text-field::-ms-input-placeholder {
    color: #222129;
    font-weight: 300;
}

.text-field::placeholder {
    color: #222129;
    font-weight: 300;
}

.text-field.big {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    font-size: 15px;
}

.text-field.big.left {
    text-align: left;
}

.text-field.left {
    text-align: left;
}

.text-field._0 {
    margin-left: 0px;
}

.div-block-12 {
    display: block;
    max-width: 800px;
    /* width: 90%; */
    margin-right: 16px;
    margin-left: 16px;
    padding: 15px;
    border-style: solid;
    border-width: 2px;
    border-color: #2ebde1;
    border-radius: 8px;
    background-color: #fff;
}

.div-block-13 {
    margin-left: 64px;
}

.div-block-14 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 55%;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: center;
    -ms-flex-pack: justify;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.rowreward {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 65px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #eee;
    text-decoration: none;
}

.rowreward:hover {
    background-color: #fbf9ff;
    cursor: pointer;
}

.form-block-2 {
    margin-bottom: 10px;
}

.section-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
}

.div-block-15 {
    border-bottom: 1px solid #ddd1d1;
}

.div-block-16 {
    width: 400px;
    height: 50%;
    margin-right: 44px;
    margin-left: 44px;
}

.section-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
}

.image-7 {
    margin-top: -58px;
    margin-left: 100px;
}

.nav-menu-2 {
    margin-bottom: -19px;
    padding-top: 0px;
    padding-bottom: 0px;
    float: right;
    font-weight: 400;
}

.text-block-14 {
    margin-top: -8px;
    color: #269686;
    font-size: 16px;
    font-weight: 500;
}

.text-block-15 {
    margin-top: -8px;
    color: #282829;
    font-size: 15px;
    font-weight: 400;
}

.lhn-links-2 {
    display: inline;
    width: auto;
    min-height: 50px;
    padding: 30px 10px 25px;
    float: left;
    border-style: none none solid;
    border-bottom-width: 4px;
    border-bottom-color: transparent;
    color: #ddd;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    text-decoration: none;
}

.lhn-links-2:hover {
    padding-top: 27px;
    padding-bottom: 23px;
    padding-left: 10px;
    border-style: solid none none;
    border-top-width: 3px;
    border-top-color: #69d2c3;
    border-bottom-width: 5px;
    border-bottom-color: #69d2c3;
    border-left-width: 3px;
    border-left-color: #69d2c3;
    background-color: transparent;
}

.lhn-links-2.w--current {
    padding: 27px 25px 25px;
    border-style: solid none none;
    border-top-width: 3px;
    border-top-color: #269686;
    border-bottom-width: 5px;
    border-bottom-color: #69d2c3;
    border-left-width: 5px;
    border-left-color: #69d2c3;
    background-color: transparent;
    background-image: none;
    font-weight: 400;
}

.lhn-links-2.w--current:hover {
    padding-top: 27px;
    background-color: transparent;
    background-image: none;
    color: #269686;
}

.navbar-2 {
    position: -webkit-sticky;
    position: sticky;
    z-index: 2000;
    width: 100%;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.85);
}

.section-5 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 29px;
    padding-bottom: 10px;
    padding-left: 29px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
}

.image-8 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.link-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
}

.link-block:hover {
    color: #4eb8a9;
    font-weight: 400;
    cursor: pointer;
}

.text-block-16 {
    -webkit-transition: color 200ms ease;
    transition: color 200ms ease;
    color: #282829;
    font-size: 15px;
}

.text-block-16.login {
    font-weight: 600;
}

.text-block-16:hover {
    color: #42e5c9;
}

.img-block-login {
    margin-left: 5px;
}

.div-block-18 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
}

.mobile-menu {
    display: none;
}

.mobile-trigger {
    display: none;
}

.newnav {
    position: relative;
    z-index: 4;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 29px;
    padding-bottom: 0px;
    padding-left: 29px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
}

.utility-page-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    max-width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.utility-page-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 260px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
}

.utility-page-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.main-content-proxydash {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 64px;
    padding-bottom: 64px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
}

.div-block-16-copy {
    width: 400px;
    margin-right: 44px;
    margin-left: 44px;
}

.heading-5-copy {
    width: 90%;
    color: #222129;
    font-size: 50px;
    line-height: 62px;
    font-weight: 700;
}

.heading-5-copy.sub {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 15px;
    font-size: 21px;
    font-weight: 500;
    text-align: center;
}

.heading-5-copy.sub2 {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 7px;
    font-size: 21px;
    line-height: 35px;
    font-weight: 500;
    text-align: left;
}

.heading-5-copy._2 {
    width: 400px;
}

.heading-5-copy._22 {
    width: 500px;
}

.heading-5-copy.bottom {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 60px;
    background-color: #fff;
    font-size: 21px;
    line-height: 40px;
    font-weight: 500;
    text-align: center;
}

.heading-5-copy._22-copy {
    width: 500px;
    margin-top: 7px;
    margin-bottom: 5px;
}

.proxy-table {
    display: block;
    height: auto;
    margin-top: -2px;
    padding-top: 0px;
    padding-right: 12px;
    padding-left: 12px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    border: 1px none #000;
    border-radius: 5px;
    background-color: #fff;
    text-align: left;
}

.proxy-table.hide {
    display: none;
}

._7-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 7%;
    height: 101%;
    margin-left: 8px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

._7-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 7%;
    height: 101%;
    margin-left: 0px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.heading1-copy {
    width: auto;
    margin-top: 0px;
    margin-left: -13px;
    padding-left: 0px;
    color: #282829;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.heading1-copy.highlight {
    margin-left: 0px;
    color: #2cbae0;
}

.heading1-copy.highlight-copy {
    margin-left: 81px;
    color: #2cbae0;
}

.body1-copy {
    margin-right: 0px;
    margin-left: 0px;
    color: #000;
}

.body1-copy.a {
    margin-left: 8px;
    font-size: 14px;
}

.body1-copy.type {
    font-size: 12px;
    font-weight: 700;
}

.body1-copy.c {
    margin-left: 0px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.body1-copy.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.body1-copy.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.body1-copy.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.body1-copy.highlight-copy {
    margin-left: 178px;
    color: #2cbae0;
}

._1-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 7%;
    height: 100%;
    margin-left: -11px;
    padding-left: 10px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

._2-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20%;
    height: 100%;
    margin-left: 20px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.heading1-copy {
    width: auto;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
    color: #282829;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.heading1-copy.highlight {
    margin-left: 0px;
    color: #2cbae0;
}

.heading1-copy.highlight-copy {
    margin-left: 0px;
    color: #2cbae0;
}

.heading1-copy {
    width: auto;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
    color: #282829;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.heading1-copy.highlight {
    margin-left: 0px;
    color: #2cbae0;
}

.heading1-copy.highlight-copy {
    margin-left: 0px;
    color: #2cbae0;
}

._3-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 9%;
    height: 101%;
    margin-left: 36px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.heading1-copy {
    width: auto;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
    color: #282829;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.heading1-copy.highlight {
    margin-left: 0px;
    color: #2cbae0;
}

.heading1-copy.highlight-copy {
    margin-left: 0px;
    color: #2cbae0;
}

._5-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 12%;
    height: 101%;
    margin-left: 37px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.heading1-copy {
    width: auto;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
    color: #282829;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.heading1-copy.highlight {
    margin-left: 0px;
    color: #2cbae0;
}

.heading1-copy.highlight-copy {
    margin-left: 0px;
    color: #2cbae0;
}

.proxyrewards1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 11%;
    height: 101%;
    margin-right: 3px;
    margin-left: 36px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.heading1-copy {
    width: auto;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
    color: #282829;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.heading1-copy.highlight {
    margin-left: 0px;
    color: #2cbae0;
}

.heading1-copy.highlight-copy {
    margin-left: 0px;
    color: #2cbae0;
}

._6-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 11%;
    height: 101%;
    margin-left: 49px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.heading1-copy {
    width: auto;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
    color: #282829;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.heading1-copy.highlight {
    margin-left: 0px;
    color: #2cbae0;
}

.heading1-copy.highlight-copy {
    margin-left: 0px;
    color: #2cbae0;
}

._1-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 7%;
    height: 100%;
    margin-left: 0px;
    padding-left: 10px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

._2-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20%;
    height: 100%;
    margin-left: 25px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

._3-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 9%;
    height: 101%;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

._5-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 12%;
    height: 101%;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.proxyrewards {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 11%;
    height: 101%;
    margin-left: 55px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

._6-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 11%;
    height: 101%;
    margin-left: 0px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.proxyfee {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 11%;
    height: 101%;
    margin-left: 35px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.proxyfee1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 11%;
    height: 101%;
    margin-left: 30px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.body1-copy {
    margin-right: 0px;
    margin-left: 0px;
    color: #000;
}

.body1-copy.a {
    margin-left: 8px;
    font-size: 14px;
}

.body1-copy.type {
    font-size: 12px;
    font-weight: 700;
}

.body1-copy.c {
    margin-left: 0px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.body1-copy.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.body1-copy.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.body1-copy.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.body1-copy.c-copy {
    margin-left: 0px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.body1-copy {
    margin-right: 0px;
    margin-left: 0px;
    color: #000;
}

.body1-copy.a {
    margin-left: 8px;
    font-size: 14px;
}

.body1-copy.type {
    font-size: 12px;
    font-weight: 700;
}

.body1-copy.c {
    margin-left: 0px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.body1-copy.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.body1-copy.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.body1-copy.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.body1-copy.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

.proxystats {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 300px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #eee;
    text-decoration: none;
    cursor: default;
}

.proxystats:hover {
    background-color: #fbf9ff;
}

.proxyinternal {
    width: 100%;
    height: auto;
    margin: 8px auto 100px;
    padding-top: 0px;
    border: 1px none #000;
    border-radius: 5px;
    background-color: #fff;
    text-align: left;
}

.rowinternal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 54px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.rowinternal1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 65px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #eee;
    text-decoration: none;
}

.rowinternal1.down {
    padding-top: 10px;
}

.rowinternal1:hover {
    background-color: #fff;
}

.row1internal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20%;
    height: 100%;
    margin-left: 20px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.row2internal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    height: 101%;
    margin-left: 91px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.row3internal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 12%;
    height: 101%;
    margin-left: 10px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.row4internal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 11%;
    height: 101%;
    margin-left: 92px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.row4internal-checkbox {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 11%;
    height: 101%;
    margin-left: 33px;
    -webkit-box-pack: start;
    -webkit-justify-content: center;
    -ms-flex-pack: start;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.headinginternal {
    width: auto;
    margin-top: 0px;
    margin-left: 20px;
    padding-left: 0px;
    color: #282829;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.headinginternal.highlight {
    margin-left: 0px;
    color: #2cbae0;
}

.headinginternal.highlight-copy {
    margin-left: 0px;
    color: #2cbae0;
}

.row2internal1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 35%;
    height: 101%;
    margin-left: 22px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.row2internal2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20%;
    height: 101%;
    margin-left: -69px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.row2internal3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 11%;
    height: 101%;
    margin-left: 35px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.row2internal3-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 11%;
    height: 101%;
    margin-left: 0px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.row2internal4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 11%;
    height: 101%;
    margin-left: 107px;
    padding-left: 2px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.internalbody {
    margin-right: 0px;
    margin-left: 3px;
    color: #000;
}

.internalbody.a {
    margin-left: 8px;
    font-size: 14px;
}

.internalbody.type {
    font-size: 12px;
    font-weight: 700;
}

.internalbody.c {
    margin-left: 0px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.internalbody.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.internalbody.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.internalbody.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.internalbody.a-copy {
    width: 39px;
    margin-left: 8px;
    font-size: 14px;
}

.section-6 {
    margin-top: 0px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.bprow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 54px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.bprow1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 65px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #eee;
    text-decoration: none;
}

.bprow1:hover {
    background-color: #fbf9ff;
}

.bprow2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 65px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #eee;
    text-decoration: none;
}

.bprow2:hover {
    background-color: #fbf9ff;
}

.bpimage {
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    padding-bottom: 0px;
    border-radius: 100%;
}

.bpbody {
    margin-right: 0px;
    margin-left: 0px;
    color: #000;
}

.bpbody.a {
    margin-left: 8px;
    font-size: 14px;
}

.bpbody.type {
    font-size: 12px;
    font-weight: 700;
}

.bpbody.c {
    margin-left: 0px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.bpbody.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.bpbody.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.bpbody.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.bpbody.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

.bpbody.c-copy {
    margin-left: 18px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.proxyrewards-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 11%;
    height: 101%;
    margin-left: 35px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.proxyfee1-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 11%;
    height: 101%;
    margin-left: 42px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.bpbody2 {
    margin-right: 0px;
    margin-left: -35px;
    color: #000;
}

.bpbody2.a {
    margin-left: 8px;
    font-size: 14px;
}

.bpbody2.type {
    font-size: 12px;
    font-weight: 700;
}

.bpbody2.c {
    margin-left: 0px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.bpbody2.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.bpbody2.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.bpbody2.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.bpbody2.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

.div-block-19 {
    margin-top: -57px;
}

.div-block-11-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: -20px;
    margin-bottom: -34px;
    margin-left: -25px;
    padding-top: 50px;
    padding-bottom: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 1px none #ddd1d1;
}

.button4 {
    margin-top: 26px;
    padding: 16px 20px 15px;
    border-radius: 11px;
    background-color: #2cbae0;
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.7px;
    user-select: none;
}

.bpbody1 {
    margin-right: 0px;
    margin-left: 33px;
    color: #000;
}

.bpbody1.a {
    margin-left: 8px;
    font-size: 14px;
}

.bpbody1.type {
    font-size: 12px;
    font-weight: 700;
}

.bpbody1.c {
    margin-left: 21px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.bpbody1.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.bpbody1.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.bpbody1.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.bpbody1.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

.actionsdiv {
    width: 400px;
    margin-right: 16px;
    margin-left: 322px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
}

.listheading {
    width: 90%;
    color: #222129;
    font-size: 50px;
    line-height: 62px;
    font-weight: 700;
}

.listheading.sub {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 15px;
    font-size: 21px;
    font-weight: 500;
    text-align: center;
}

.listheading.sub2 {
    width: 100%;
    margin-top: 14px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 7px;
    font-size: 21px;
    line-height: 35px;
    font-weight: 500;
    text-align: left;
}

.listheading._2 {
    width: 400px;
}

.listheading._22 {
    width: 500px;
    margin-top: -15px;
    font-size: 45px;
}

.listheading.bottom {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 60px;
    background-color: #fff;
    font-size: 21px;
    line-height: 40px;
    font-weight: 500;
    text-align: center;
}

.listheading._22-copy {
    width: 500px;
}

.listheading._22-copy {
    width: 500px;
    font-size: 30px;
    text-align: center;
}

.text-block-13-copy {
    margin-top: 13px;
    color: #8a8bac;
    font-size: 17px;
    line-height: 29px;
    font-weight: 400;
    text-align: left;
}

.text-block-13-copy.centre {
    width: 608px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 20px;
    padding-bottom: 60px;
    text-align: center;
}

.text-block-13-copy.centre.correct {
    width: 400px;
    padding-top: 0px;
}

.text-block-13-copy.info {
    margin-top: 31px;
    color: #667;
    font-size: 15px;
    line-height: 24px;
    font-weight: 300;
    text-align: center;
}

.text-block-13-copy.sub3 {
    margin-top: 31px;
    color: #222129;
    font-weight: 500;
}

.link-block-4 {
    width: 10%;
}

.image-9 {
    margin-top: 130px;
}

.divinfo {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 8%;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.div-block-21 {
    width: 60px;
    height: 30px;
    border: 1px solid #000;
    border-radius: 20px;
}

.text-block-17 {
    padding: 5px 15px;
    border: 1px solid #000;
    border-radius: 6px;
    color: #000;
    text-align: center;
}

.rowrewarddiv {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 65px;
    margin-left: -7px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.rowrewarddiv-loading {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 65px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
}

.rowrewarddiv:hover {
    background-color: #fbf9ff;
    cursor: pointer;
}

.link-block-5 {
    padding: 5px 15px;
}

.link-6 {
    margin-left: -22px;
    padding: 4px 14px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #01010f;
    text-decoration: none;
}

.link-6:hover {
    background-color: #e4dcf3;
    cursor: pointer;
}

.heading-6 {
    margin-left: 346px;
    font-size: 20px;
}

.link-block-6 {
    margin-left: -68px;
    padding-left: 0px;
}

.footer-copy {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 2px solid #f2f4f8;
    text-align: right;
}

.brand-logo {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    float: left;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    -webkit-transition: opacity 100ms ease-in-out;
    transition: opacity 100ms ease-in-out;
}

.brand-logo:hover {
    opacity: 0.8;
}

.footer-copy-link {
    margin-left: 10px;
    float: right;
    color: #95979b;
    text-decoration: none;
}

.footer-copy-link:hover {
    color: #fd8274;
}

.footer-columns {
    height: 50%;
    margin-right: -10px;
    margin-left: -10px;
    padding-top: 60px;
    border-top: 2px none #f2f4f8;
}

.footer-link {
    display: block;
    margin-top: 20px;
    padding-right: 5px;
    -webkit-transition: opacity 100ms ease-in-out;
    transition: opacity 100ms ease-in-out;
    color: #95979b;
    text-decoration: none;
}

.footer-link:hover {
    color: #ffa185;
    text-decoration: none;
}

.footer-link.text-small {
    margin-top: 10px;
}

.footer-social {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    margin-left: 0px;
    border-radius: 8px;
    background-color: #f2f4f8;
    -webkit-transition: background-color 200ms ease-in-out;
    transition: background-color 200ms ease-in-out;
    color: #000;
}

.footer-social.facebook {
    border-style: solid;
    border-width: 2px;
    border-color: #f2f4f8;
    background-color: #f2f4f8;
    background-image: url('../images/Telegram-logo.png');
    background-position: 0px 0px;
    background-size: cover;
}

.footer-social.facebook:hover {
    background-color: #fff;
}

.footer-social.medium {
    border-style: solid;
    border-width: 2px;
    border-color: #f2f4f8;
    background-color: #f2f4f8;
    background-image: url('../images/medium.png');
    background-position: 0px 0px;
    background-size: cover;
}

.footer-social.medium:hover {
    background-color: #fff;
}

.footer-social.instagram {
    border-style: solid;
    border-width: 2px;
    border-color: #f2f4f8;
    background-color: #f2f4f8;
    background-image: url('../images/instagram.svg');
    background-position: 50% 50%;
    background-size: 25px;
    background-repeat: no-repeat;
}

.footer-social.instagram:hover {
    background-color: #fff;
}

.footer-social.twitter {
    margin-right: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: #f2f4f8;
    background-color: #f2f4f8;
    background-image: url('../images/twitter.svg');
    background-position: 50% 50%;
    background-size: 23px;
    background-repeat: no-repeat;
}

.footer-social.twitter:hover {
    background-color: #fff;
}

.footer-social.twitter {
    border-style: solid;
    border-width: 2px;
    border-color: #f2f4f8;
    background-color: #f2f4f8;
    background-image: url('../images/twitter.svg');
    background-position: 50% 50%;
    background-size: 23px;
    background-repeat: no-repeat;
}

.footer-social.twitter:hover {
    background-color: #fff;
}

.footer-social.facebook {
    margin-left: -70px;
    border-style: solid;
    border-width: 2px;
    border-color: #f2f4f8;
    background-color: #f2f4f8;
    background-image: url('../images/Telegram-logo.png');
    background-position: 0px 0px;
    background-size: cover;
}

.footer-social.facebook:hover {
    background-color: #fff;
}

.footer-social.fb {
    background-image: url('../images/Telegram-logo.png');
    background-position: 50% 50%;
    background-size: 80%;
    background-repeat: no-repeat;
}

.footer-nav-title {
    margin-top: 0px;
}

.footer-social-icons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 25px;
    text-align: left;
}

.footer-2 {
    padding-right: 40px;
    padding-left: 40px;
    background-color: #fff;
}

.footer-links {
    margin-bottom: 60px;
}

.footer-copy-text {
    color: #95979b;
    text-align: left;
}

.image-10 {
    margin-top: -12px;
    margin-left: 0px;
}

.heading-7 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 71px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

.paragraph-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 500px;
    float: none;
    clear: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    font-size: 17px;
    text-align: center;
}

.link-7 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 272px;
    color: #2cbae0;
}

.link-6-copy {
    margin-left: 132px;
    padding: 4px 14px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #01010f;
    text-decoration: none;
}

.link-6-copy:hover {
    background-color: #e4dcf3;
    cursor: pointer;
}

.link-6-copy {
    margin-left: 3px;
    padding: 4px 14px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #01010f;
    text-decoration: none;
}

.link-6-copy:hover {
    background-color: #e4dcf3;
}

.row2internal2-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20%;
    height: 101%;
    margin-left: 89px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.heading-8 {
    width: 74%;
    margin-top: 41px;
    margin-bottom: 13px;
    margin-left: 170px;
    padding-top: 0px;
    font-size: 20px;
}

.proxyinternal-copy {
    width: 80%;
    height: auto;
    max-width: 1050px;
    margin: 20px 10px 100px;
    padding-top: 10px;
    padding-right: 12px;
    padding-left: 12px;
    border: 1px none #000;
    border-radius: 5px;
    background-color: #fff;
    text-align: left;
}

.bpInternal-table {
    width: 80%;
    height: auto;
    max-width: 1000px;
    margin: 20px 10px 100px;
    padding-top: 10px;
    padding-right: 12px;
    padding-left: 12px;
    border: 1px none #000;
    border-radius: 5px;
    background-color: #fff;
    text-align: left;
}

.column1-regBp {
    width: 150px;
}

.column2-regBp {
    width: 150px;
}

.column3-regBp {
    width: 150px;
}

.column4-regBp {
    width: 300px;
}

.column5-regBp {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.column6-regBp {
    width: 230px;
    min-width: 200px;
}

.column7-regBp {
    width: 50px;
}

.column6-regBp.rows {
    padding-left: 70px;
}

.regbobod {
    margin-right: 0px;
    margin-left: -48px;
    color: #000;
}

.regbobod.a {
    margin-left: 8px;
    font-size: 14px;
}

.regbobod.type {
    font-size: 12px;
    font-weight: 700;
}

.regbobod.c {
    margin-left: 4px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.regbobod.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.regbobod.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.regbobod.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.regbobod.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

.regbobod1 {
    margin-right: 0px;
    margin-left: -47px;
    color: #000;
}

.regbobod1.a {
    margin-left: 8px;
    font-size: 14px;
}

.regbobod1.type {
    font-size: 12px;
    font-weight: 700;
}

.regbobod1.c {
    margin-left: 4px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.regbobod1.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.regbobod1.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.regbobod1.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.regbobod1.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

.regbobod2 {
    margin-right: 0px;
    margin-left: -44px;
    color: #000;
}

.regbobod2.a {
    margin-left: 8px;
    font-size: 14px;
}

.regbobod2.type {
    font-size: 12px;
    font-weight: 700;
}

.regbobod2.c {
    margin-left: 4px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.regbobod2.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.regbobod2.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.regbobod2.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.regbobod2.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

.heading-9 {
    width: 70%;
    margin-top: 41px;
    margin-left: 141px;
    font-size: 20px;
}

.row2internal1-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 35%;
    height: 101%;
    margin-left: 9px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.row2internal1-copy.down {
    margin-top: 20px;
}

.row2internal2-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20%;
    height: 101%;
    margin-left: 35px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.div-block-14-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
}

.link-8 {
    color: #2cbae0;
    text-align: left;
}

.select-field-2 {
    width: 100%;
    height: 36px;
    min-height: 36px;
    margin-top: 2px;
    background-color: #f2f3f3;
    font-size: 14px;
}

.full-containter {
    width: 90%;
    max-width: 970px;
    background-color: #fff;
}

.section-7 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    max-width: none;
    min-height: 65vh;
    padding-top: 125px;
    padding-bottom: 150px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
}

.tabs-menu-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: -74px;
}

.tab-link-tab-2 {
    background-color: #69d2c3;
    color: #fff;
    font-size: 20px;
    line-height: 40px;
    font-weight: 700;
}

.tab-link-tab-2.right {
    border-top-right-radius: 8px;
    background-color: #e8e8e8;
    color: #fff;
}

.tab-link-tab-2.right:active {
    background-color: #e8e8e8;
}

.tab-link-tab-2.right.w--current {
    border-radius: 0px;
    background-color: #fff;
    box-shadow: 4px 4px 3px 0 #f2f3f3;
}

.tab-link-tab-2.right.w--current:active {
    background-color: #f2f3f3;
}

.tab-link-tab-2.left {
    border-top-left-radius: 8px;
    background-color: #e8e8e8;
    color: #f2f3f3;
}

.tab-link-tab-2.left.w--current {
    background-color: #fff;
    color: #fff;
}

.tab-link-tab-2.left.w--current:active {
    background-color: #e8e8e8;
}

.tab-link-tab-2.side {
    border-right: 3px solid transparent;
    background-color: #fff;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #000;
    text-align: left;
}

.tab-link-tab-2.side:hover {
    border-right: 3px solid #4eb8a9;
}

.tab-link-tab-2.side:active {
    background-color: #e8e8e8;
}

.tab-link-tab-2.side.w--current {
    border-right: 3px solid #4eb8a9;
    text-align: left;
}

.tab-link-tab-2.side.w--current:active {
    background-color: #e8e8e8;
}

.tabs-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    /* height: 90vh; */
}

.proxy-copy {
    margin-top: 20px;
}

.tabs-menu-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-color: #fff;
}

.tab-link-tab-2-2 {
    width: 200px;
    text-align: center;
}

.tabs-content-4 {
    max-width: 1000px;
    width: 100%;
    padding-top: 0px;
    padding-left: 25px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.div-block-22 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.div-block-23 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    text-align: center;
}

.container-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.div-block-24 {
    padding: 50px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 13px 7px hsla(0, 0%, 60%, 0.14);
}

.tabs-4 {
    width: 100.06641%;
    max-width: none;
    box-shadow: -12px 12px 20px -15px rgba(0, 0, 0, 0.2);
}

.text-block-18 {
    font-size: 15px;
}

.div-block-25 {
    display: -ms-grid;
    display: grid;
    width: 100%;
    padding: 15px 100px 14px 25px;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-areas: ". . . . . ."". . . . . ."". . . . . ."". . . . . Area";
    -ms-grid-columns: 0.25fr 16px 0.75fr 16px 0.25fr 16px 0.25fr 16px 0.25fr 16px 0.25fr;
    grid-template-columns: 0.25fr 0.75fr 0.25fr 0.25fr 0.25fr 0.25fr;
    -ms-grid-rows: auto 16px auto 16px auto 16px auto;
    grid-template-rows: auto auto auto auto;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #fff;
}

.div-block-25.small {
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
}

.div-block-26 {
    text-align: center;
}

.text-block-19 {
    font-weight: 700;
}

.text-block-20 {
    font-weight: 700;
    text-align: center;
}

.text-block-21 {
    font-weight: 700;
    text-align: center;
}

.div-block-27 {
    text-align: center;
}

.text-block-22 {
    font-weight: 700;
}

.div-block-28 {
    text-align: center;
}

.div-block-29 {
    text-align: center;
}

.text-block-23 {
    font-weight: 700;
}

.text-block-24 {
    font-weight: 700;
}

.div-block-30 {
    text-align: center;
}

.div-block-31 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
}

.div-block-31a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 15px;
    padding-left: 60px;
    -webkit-box-pack: left;
    -webkit-justify-content: left;
    -ms-flex-pack: left;
    justify-content: left;
    -webkit-box-align: left;
    -webkit-align-items: left;
    -ms-flex-align: center;
    align-items: left;
    text-decoration: none;
}

.text-block-25 {
    text-align: center;
}

.div-block-32 {
    padding-top: 18px;
    color: #000;
    text-align: center;
    text-decoration: none;
}

.text-block-26 {
    text-align: center;
}

.text-block-26.align-left {
    text-align: left;
}

.div-block-33 {
    padding-top: 16px;
    color: #282829;
    text-align: center;
    text-decoration: none;
}

.text-block-27 {
    text-align: center;
}

.div-block-34 {
    padding-top: 15px;
    color: #282829;
    text-align: center;
    text-decoration: none;
}

.div-block-34a {
    color: #282829;
    text-align: center;
    text-decoration: none;
    min-width: 96px;
    padding-top: 16px;
}

.text-block-28 {
    text-align: center;
}

.votebutton {
    margin-left: -120px;
    padding: 4px 14px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #01010f;
    text-decoration: none;
}

.votebutton:hover {
    background-color: #e4dcf3;
}

.votebutton1 {
    margin-left: -52px;
    padding: 4px 14px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #01010f;
    text-decoration: none;
}

.votebutton1:hover {
    background-color: #e4dcf3;
}

.link-6-copy {
    margin-left: -52px;
    padding: 4px 14px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #01010f;
    text-decoration: none;
}

.link-6-copy:hover {
    background-color: #e4dcf3;
}

.proxyimage {
    margin-top: -2px;
    margin-right: 0px;
    margin-left: 0px;
    padding-bottom: 0px;
    border-radius: 100%;
}

.proxyimage2 {
    margin-top: -2px;
    margin-right: 0px;
    margin-left: 0px;
    padding-bottom: 0px;
    border-radius: 100%;
}

.body1-copy-copy {
    margin-right: 0px;
    margin-left: 0px;
    color: #000;
}

.body1-copy-copy.a {
    margin-left: 8px;
    font-size: 14px;
}

.body1-copy-copy.type {
    font-size: 12px;
    font-weight: 700;
}

.body1-copy-copy.c {
    margin-left: 0px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.body1-copy-copy.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.body1-copy-copy.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.body1-copy-copy.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.body1-copy-copy.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

.body1-copy-copy.a-copy-copy {
    margin-left: 8px;
    font-size: 14px;
}

.proxyimage3 {
    margin-top: -2px;
    margin-right: 0px;
    margin-left: 0px;
    padding-bottom: 0px;
    border-radius: 100%;
}

.proxyimage4 {
    margin-top: -2px;
    margin-right: 0px;
    margin-left: 0px;
    padding-bottom: 0px;
    border-radius: 100%;
}

.heading-10 {
    margin-top: -13px;
    margin-bottom: 40px;
    margin-left: 15px;
    text-align: left;
}

.image-11 {
    position: static;
    display: block;
    margin-top: -75px;
    margin-left: 42px;
}

.text-block-29 {
    font-size: 15px;
}

.text-block-30 {
    font-size: 15px;
}

.addbutton {
    padding: 4px 14px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #01010f;
    text-decoration: none;
}

.addbutton:hover {
    background-color: #e4dcf3;
    cursor: pointer;
}

.link-9 {
    color: #2cbae0;
}

.link-10 {
    color: #2cbae0;
}

.div-block-35 {
    display: block;
    width: 45%;
    margin-right: 16px;
    margin-left: 16px;
}

.image-12 {
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    padding-bottom: 0px;
    border-radius: 100%;
}

.image-12.exchange {
    margin-left: 40px;
}

.image-13 {
    margin-top: -12px;
    margin-left: 19px;
}

.heading1-2 {
    width: auto;
    margin-top: 0px;
    margin-left: -8px;
    padding-left: 0px;
    color: #282829;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.heading1-2.highlight {
    margin-left: 0px;
    color: #2cbae0;
}

.div-block-36 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 70px;
    padding-bottom: 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 1px solid #ddd1d1;
}

.footer-social-icons-2 {
    margin-top: 40px;
}

.link-11 {
    margin-left: 16px;
    padding: 4px 14px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #fff;
    background-color: #2cbae0;
    text-decoration: none;
}

.mainVoteBtn {
    width: 60px;
    height: 31px;
    user-select: none;
    /* background-color: #2cbae0; */
}

.mainInfoBtn {
    height: 31px;
    user-select: none;
}
.mainInfoBtn:hover {
    cursor: pointer;
}

.link-11:hover {
    background-color: #e4dcf3;
    cursor: pointer;
    color: #000;
}

.div-block-37 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    max-width: 1200px;
    margin-left: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.text-field-2 {
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
    padding-left: 11px;
    border-style: solid;
    border-width: 1px;
    border-color: #d8e7e5;
    border-radius: 9px;
    color: #222129;
    font-size: 13px;
    font-weight: 400;
}

.text-field-2::-webkit-input-placeholder {
    color: #222129;
    font-weight: 300;
}

.text-field-2:-ms-input-placeholder {
    color: #222129;
    font-weight: 300;
}

.text-field-2::-ms-input-placeholder {
    color: #222129;
    font-weight: 300;
}

.text-field-2::placeholder {
    color: #222129;
    font-weight: 300;
}

.text-block-31 {
    margin-top: 2px;
}

.link-block-7 {
    color: #282829;
    text-decoration: none;
}

.div-block-38 {
    padding-top: 16px;
    color: #282829;
    text-decoration: none;
}

.div-block-39 {
    width: auto;
    padding-top: 17px;
    color: #282829;
    text-decoration: none;
}

.listproxyheading {
    width: 90%;
    color: #222129;
    font-size: 50px;
    line-height: 62px;
    font-weight: 700;
}

.listproxyheading.sub {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 15px;
    font-size: 21px;
    font-weight: 500;
    text-align: center;
}

.listproxyheading.sub2 {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 0px;
    margin-left: 158px;
    padding-top: 0px;
    padding-bottom: 7px;
    font-size: 21px;
    line-height: 35px;
    font-weight: 500;
    text-align: center;
}

.listproxyheading.sub2.nay {
    margin-top: 0px;
    margin-bottom: 15px;
    text-align: left;
}

.listproxyheading.sub2.left {
    text-align: left;
}

.listproxyheading._2 {
    width: 400px;
}

.listproxyheading._22 {
    width: 70%;
    margin-top: -10px;
    margin-left: -70px;
    font-size: 25px;
}

.listproxyheading.bottom {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 60px;
    background-color: #fff;
    font-size: 21px;
    line-height: 40px;
    font-weight: 500;
    text-align: center;
}

.listproxyheading._22-copy {
    width: 500px;
}

.listproxyheading.sub2 {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 7px;
    font-size: 21px;
    line-height: 35px;
    font-weight: 500;
    text-align: left;
}

.fundtittle {
    width: 90%;
    color: #222129;
    font-size: 50px;
    line-height: 62px;
    font-weight: 700;
}

.fundtittle.sub {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 15px;
    font-size: 21px;
    font-weight: 500;
    text-align: center;
}

.fundtittle.sub2 {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 0px;
    margin-left: 167px;
    padding-top: 0px;
    padding-bottom: 7px;
    font-size: 21px;
    line-height: 35px;
    font-weight: 500;
    text-align: center;
}

.fundtittle.sub2.nay {
    margin-top: 0px;
    margin-bottom: 15px;
    text-align: left;
}

.fundtittle.sub2.left {
    text-align: left;
}

.fundtittle._2 {
    width: 400px;
}

.fundtittle._22 {
    width: 70%;
    margin-top: -10px;
    margin-left: -70px;
    font-size: 25px;
}

.fundtittle.bottom {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 60px;
    background-color: #fff;
    font-size: 21px;
    line-height: 40px;
    font-weight: 500;
    text-align: center;
}

.fundtittle._22-copy {
    width: 500px;
}

.fundtittle.sub2 {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 7px;
    font-size: 21px;
    line-height: 35px;
    font-weight: 500;
    text-align: left;
}

.heading-5-copy {
    width: 90%;
    color: #222129;
    font-size: 50px;
    line-height: 62px;
    font-weight: 700;
}

.heading-5-copy.sub {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 15px;
    font-size: 21px;
    font-weight: 500;
    text-align: center;
}

.heading-5-copy.sub2 {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 0px;
    margin-left: 132px;
    padding-top: 0px;
    padding-bottom: 7px;
    font-size: 21px;
    line-height: 35px;
    font-weight: 500;
    text-align: center;
}

.heading-5-copy.sub2.nay {
    margin-top: 0px;
    margin-bottom: 15px;
    text-align: left;
}

.heading-5-copy.sub2.left {
    text-align: left;
}

.heading-5-copy._2 {
    width: 400px;
}

.heading-5-copy._22 {
    width: 70%;
    margin-top: -10px;
    margin-left: -70px;
    font-size: 25px;
}

.heading-5-copy.bottom {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 60px;
    background-color: #fff;
    font-size: 21px;
    line-height: 40px;
    font-weight: 500;
    text-align: center;
}

.heading-5-copy._22-copy {
    width: 500px;
}

.heading-5-copy.sub2 {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 7px;
    font-size: 21px;
    line-height: 35px;
    font-weight: 500;
    text-align: left;
}

.heading-5-copy-copy {
    width: 90%;
    color: #222129;
    font-size: 50px;
    line-height: 62px;
    font-weight: 700;
}

.heading-5-copy-copy.sub {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 15px;
    font-size: 21px;
    font-weight: 500;
    text-align: center;
}

.heading-5-copy-copy.sub2 {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 0px;
    margin-left: 199px;
    padding-top: 0px;
    padding-bottom: 7px;
    font-size: 21px;
    line-height: 35px;
    font-weight: 500;
    text-align: center;
}

.heading-5-copy-copy.sub2.nay {
    margin-top: 0px;
    margin-bottom: 15px;
    text-align: left;
}

.heading-5-copy-copy.sub2.left {
    text-align: left;
}

.heading-5-copy-copy._2 {
    width: 400px;
}

.heading-5-copy-copy._22 {
    width: 70%;
    margin-top: -10px;
    margin-left: -70px;
    font-size: 25px;
}

.heading-5-copy-copy.bottom {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 60px;
    background-color: #fff;
    font-size: 21px;
    line-height: 40px;
    font-weight: 500;
    text-align: center;
}

.heading-5-copy-copy._22-copy {
    width: 500px;
}

.heading-5-copy-copy.sub2 {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 7px;
    font-size: 21px;
    line-height: 35px;
    font-weight: 500;
    text-align: left;
}

.text-block-32 {
    color: #282829;
    font-size: 18px;
}

.text-block-33 {
    color: #282829;
    font-size: 18px;
}

.form-2 {
    width: 100%;
    height: 36px;
}

.text-block-13-copy {
    margin-top: 13px;
    color: #8a8bac;
    font-size: 17px;
    line-height: 29px;
    font-weight: 400;
    text-align: left;
}

.text-block-13-copy.centre {
    width: 608px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 20px;
    padding-bottom: 60px;
    text-align: center;
}

.text-block-13-copy.centre.correct {
    width: 400px;
    padding-top: 0px;
}

.text-block-13-copy.info {
    width: 100%;
    margin-top: 4px;
    margin-bottom: -31px;
    margin-left: -14px;
    color: #667;
    font-size: 15px;
    line-height: 24px;
    font-weight: 300;
    text-align: center;
}

.text-block-13-copy.info.left {
    width: 100%;
    margin-top: -6px;
    text-align: left;
}

.text-block-13-copy.sub3 {
    margin-top: 31px;
    color: #222129;
    font-weight: 500;
}

.text-block-13-copy.info {
    margin-top: 0px;
    color: #667;
    font-size: 15px;
    line-height: 24px;
    font-weight: 300;
}

.heading1-2-copy {
    width: auto;
    margin-top: 0px;
    margin-left: -15px;
    padding-left: 0px;
    color: #282829;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.heading1-2-copy.highlight {
    margin-left: 0px;
    color: #2cbae0;
}

.link-11-copy {
    margin-left: -21px;
    padding: 4px 14px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #01010f;
    text-decoration: none;
}

.link-11-copy:hover {
    background-color: #e4dcf3;
}

.howmanyeos {
    display: block;
    width: 40%;
    height: auto;
    margin-right: -120px;
    margin-left: 30px;
    padding: 15px;
    border: 2px solid #2cbae0;
    border-radius: 8px;
    background-color: #fff;
}

.text-block-13-copy-copy {
    margin-top: 13px;
    color: #8a8bac;
    font-size: 17px;
    line-height: 29px;
    font-weight: 400;
    text-align: left;
}

.text-block-13-copy-copy.centre {
    width: 608px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 20px;
    padding-bottom: 60px;
    text-align: center;
}

.text-block-13-copy-copy.centre.correct {
    width: 400px;
    padding-top: 0px;
}

.text-block-13-copy-copy.info {
    margin-top: 31px;
    color: #667;
    font-size: 15px;
    line-height: 24px;
    font-weight: 300;
    text-align: center;
}

.text-block-13-copy-copy.warnnig {
    color: #667;
    font-size: 15px;
    line-height: 24px;
    font-weight: 300;
    text-align: center;
}

.toggle-group {
    position: relative;
    display: inline-block;
}

.tooltiptext-main-column5 {
    visibility: hidden;
    width: 170px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: 80%;
    left: 15%;
    margin-left: -90px;
    font-size: 13px;
}

.tooltiptext-main-column5::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 90%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.column5:hover .tooltiptext-main-column5 {
    visibility: visible;
}

.tooltiptext-main-column6 {
    visibility: hidden;
    width: 170px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: 80%;
    left: 15%;
    margin-left: -90px;
    font-size: 13px;
}

.tooltiptext-main-column6::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 90%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.column6:hover .tooltiptext-main-column6 {
    visibility: visible;
}

.tooltiptext-main-column7 {
    visibility: hidden;
    width: 170px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: 80%;
    left: 15%;
    margin-left: -90px;
    font-size: 13px;
}

.tooltiptext-main-column7::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 90%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.column7:hover .tooltiptext-main-column7 {
    visibility: visible;
}

.tooltiptext-main-column8 {
    visibility: hidden;
    width: 170px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: 80%;
    left: 15%;
    margin-left: -90px;
    font-size: 13px;
}

.tooltiptext-main-column8::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 90%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.column8:hover .tooltiptext-main-column8 {
    visibility: visible;
}

.toggle-group .tooltiptext {
    visibility: hidden;
    width: 400px;
    height: auto;
    background-color: rgb(31, 31, 31);
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 8px 8px;
    position: absolute;
    z-index: 1;
    bottom: 110%;
    left: 10%;
    margin-left: -60px;
}

.toggle-group .tooltiptext-proxy {
    visibility: hidden;
    width: 440px;
    height: auto;
    background-color: rgb(31, 31, 31);
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 8px 8px;
    position: absolute;
    bottom: 90%;
    left: 10%;
    margin-left: -60px;
    font-size: 13px;
}

.toggle-group .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.toggle-group .tooltiptext-proxy::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 10%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.toggle-group:hover .tooltiptext {
    visibility: visible;
}

.toggle-group:hover .tooltiptext-proxy {
    visibility: visible;
}

.text-block-13-copy-copy.sub3 {
    margin-top: 31px;
    color: #222129;
    font-weight: 500;
}

.div-block-40 {
    margin-top: -56px;
    margin-bottom: 43px;
}

.text-block-34 {
    margin-top: 2px;
}

.text-block-35 {
    margin-top: 2px;
}

.bpregisterbutton {
    display: block;
    margin-left: 0px;
    padding: 4px 14px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #01010f;
    text-decoration: none;
    width: 82px;
}

.bpregisterbutton:hover {
    background-color: #e4dcf3;
    cursor: pointer;
}

.bptextbox {
    margin-top: 13px;
    color: #8a8bac;
    font-size: 17px;
    line-height: 29px;
    font-weight: 400;
    text-align: left;
}

.bptextbox.centre {
    width: 608px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 20px;
    padding-bottom: 60px;
    text-align: center;
}

.bptextbox.centre.correct {
    width: 400px;
    padding-top: 0px;
}

.bptextbox.info {
    width: 100%;
    margin-top: -28px;
    margin-left: 2px;
    color: #667;
    font-size: 5px;
    line-height: 24px;
    font-weight: 300;
    text-align: center;
}

.bptextbox.info.left {
    width: 100%;
    margin-top: -6px;
    margin-left: -4px;
    text-align: left;
}

.bptextbox.sub3 {
    margin-top: 31px;
    color: #222129;
    font-weight: 500;
}

.bptextbox.info {
    margin-top: 0px;
    color: #667;
    font-size: 15px;
    line-height: 24px;
    font-weight: 300;
}

.text-block-36 {
    margin-top: 9px;
    color: #667;
    font-size: 12px;
    font-weight: 300;
}

.link-12 {
    color: #2cbae0;
}

.link-11-copy {
    margin-left: -12px;
    padding: 4px 14px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #01010f;
    text-decoration: none;
}

.link-11-copy:hover {
    background-color: #e4dcf3;
}

.link-11-copy {
    margin-left: -12px;
    padding: 4px 14px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #01010f;
    text-decoration: none;
}

.link-11-copy:hover {
    background-color: #e4dcf3;
}

.infobutton {
    /* margin-left: 50px; */
    padding: 4px 14px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #01010f;
    text-decoration: none;
}

.infobutton:hover {
    background-color: #e4dcf3;
}

.body1-copy {
    margin-right: 0px;
    margin-left: 6px;
    color: #000;
}

.body1-copy.a {
    margin-left: 8px;
    font-size: 14px;
}

.body1-copy.type {
    font-size: 12px;
    font-weight: 700;
}

.body1-copy.c {
    /* margin-left: 58px; */
    margin-left: 30px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.body1-copy.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.body1-copy.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.body1-copy.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.body1-copy.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

.regbobod-copy {
    margin-right: 0px;
    margin-left: 17px;
    color: #000;
}

.regbobod-copy.a {
    margin-left: 8px;
    font-size: 14px;
}

.regbobod-copy.type {
    font-size: 12px;
    font-weight: 700;
}

.regbobod-copy.c {
    margin-left: 4px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.regbobod-copy.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.regbobod-copy.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.regbobod-copy.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.regbobod-copy.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

.rewards {
    margin-right: 0px;
    margin-left: 6px;
    color: #000;
}

.rewards.a {
    margin-left: 8px;
    font-size: 14px;
}

.rewards.type {
    font-size: 12px;
    font-weight: 700;
}

.rewards.c {
    margin-left: -4px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.rewards.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.rewards.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.rewards.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.rewards.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

.daily {
    margin-right: 0px;
    margin-left: 6px;
    color: #000;
}

.daily.a {
    margin-left: 8px;
    font-size: 14px;
}

.daily.type {
    font-size: 12px;
    font-weight: 700;
}

.daily.c {
    margin-left: -4px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.daily.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.daily.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.daily.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.daily.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

.estreturn {
    margin-right: 0px;
    margin-left: 6px;
    color: #000;
}

.estreturn.a {
    margin-left: 8px;
    font-size: 14px;
}

.estreturn.type {
    font-size: 12px;
    font-weight: 700;
}

.estreturn.c {
    margin-left: 4px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.estreturn.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.estreturn.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.estreturn.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.estreturn.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

.apr {
    margin-right: 0px;
    margin-left: 6px;
    color: #000;
}

.apr.a {
    margin-left: 8px;
    font-size: 14px;
}

.apr.type {
    font-size: 12px;
    font-weight: 700;
}

.apr.c {
    margin-left: -43px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.apr.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.apr.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.apr.highlight {
    margin-left: -3px;
    color: #2cbae0;
}

.apr.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

.stakedamount {
    margin-right: 0px;
    margin-left: 6px;
    color: #000;
}

.stakedamount.a {
    margin-left: 8px;
    font-size: 14px;
}

.stakedamount.type {
    font-size: 12px;
    font-weight: 700;
}

.stakedamount.c {
    margin-left: -3px;
    padding-left: 0px;
    font-weight: 400;
    text-align: left;
}

.stakedamount.left {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
}

.stakedamount.left.bold {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.stakedamount.highlight {
    margin-left: -9px;
    color: #2cbae0;
}

.stakedamount.a-copy {
    margin-left: 8px;
    font-size: 14px;
}

.votebps {
    margin-left: 16px;
    padding: 4px 14px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #01010f;
    text-decoration: none;
}

.votebps:hover {
    background-color: #e4dcf3;
    cursor: pointer;
}

.div-block-41 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.div-block-42 {
    width: 16%;
}

.div-block-42.big {
    width: 25%;
}

.div-block-25-copy {
    display: -ms-grid;
    display: grid;
    width: 100%;
    padding: 15px 100px 14px 25px;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-areas: ".";
    -ms-grid-columns: 0.25fr 16px 0.75fr 16px 0.25fr 16px 0.25fr 16px 0.25fr 16px 0.25fr;
    grid-template-columns: 0.25fr 0.75fr 0.25fr 0.25fr 0.25fr 0.25fr;
    -ms-grid-rows: auto 16px auto;
    grid-template-rows: auto auto;
    border-bottom: 1px solid hsla(0, 0%, 86%, 0.65);
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #fff;
}

.div-block-25-copy.small {
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
}

.div-block-25-copy.smaller {
    padding-top: 5px;
    padding-bottom: 15px;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.div-block-25-copy.smaller.last {
    border-bottom-style: none;
}

.div-block-25-copy.smaller.hover {
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
}

.div-block-25-copy.smaller.hover:hover {
    background-color: #f3f3f3;
}

.div-block-25-copy.smaller.top {
    padding-top: 15px;
}

.div-block-43 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 13px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #fff;
}

.checkbox-field-2 {
    margin-top: 76px;
    margin-left: 34px;
}

.form-block-3 {
    margin-top: -26px;
}

.checkbox-label-2 {
    margin-top: 75px;
}

.checkbox-field-3 {
    margin-top: 24px;
}

.rich-text-block-2 {
    text-align: left;
}

.link-13 {
    margin-left: 31px;
}

.lang-div {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.lang-wrap {
    position: absolute;
    /* left: 0%; */
    top: 40px;
    right: -40px;
    /* right: auto; */
    bottom: auto;
    display: none;
    width: 130px;
    z-index: 50;
}

.lang-wrap.active {
    display: block;
}

.link-block-16 {
    position: static;
    left: 0%;
    top: 35px;
    right: auto;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    padding: 8px 6px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #f0fdfb;
    text-decoration: none;
}

.link-block-16.first {
    padding-right: 0px;
    padding-bottom: 4px;
    padding-left: 6px;
    cursor: pointer;
    border-top: 1px solid #dee3fe;
    border-left: 1px solid #dee3fe;
    border-right: 1px solid #dee3fe;
}

.link-block-16.first:hover {
    background-color: rgba(133, 132, 132, 1);
}

.link-block-16.last {
    padding-top: 2px;
    padding-bottom: 5px;
    border-radius: 0px 0px 10px 10px;
    cursor: pointer;
    border-left: 1px solid #dee3fe;
    border-right: 1px solid #dee3fe;
    border-bottom: 1px solid #dee3fe;
}

.link-block-16.last:hover {
    background-color: rgba(133, 132, 132, 1);
}

.link-block-16.normal {
    padding-top: 2px;
    padding-bottom: 5px;
    border-radius: 0px;
    cursor: pointer;
    border-left: 1px solid #dee3fe;
    border-right: 1px solid #dee3fe;
}

.link-block-16.normal:hover {
    background-color: rgba(133, 132, 132, 1);
}

.text-block {
    font-family: Montserrat, sans-serif;
    color: rgb(53, 52, 52);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.8px;
    text-decoration: none;
    user-select: none;
    padding-bottom: 6px;
}

.image-65 {
    margin-left: 5px;
    margin-right: 10px;
}

.pop-up {
    position: fixed;
    z-index: 5;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(71, 71, 71, 0.52);
    text-align: center;
}

.pop-up-wrap {
    position: relative;
    width: 90%;
    max-width: 900px;
    height: auto;
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 10px;
    background-image: linear-gradient(87deg, #f7f4fa, #f5fffe), url('../images/Topography-bg.png');
}

.pop-up-wrap.Referral {
    max-width: 488px;
}

.pop-up-block-1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.pop-up-block-10 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 30px;
    padding: 0px 20px;
}

.pop-up-block-11 {
    font-size: 40px;
    font-weight: 700;
}

.pop-up-block-12 {
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
}

.pop-up-block-13 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0px 20px;
    margin-bottom: 30px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pop-up-block-14 {
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
}

.pop-up-block-15 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0px 20px;
    padding: 0px 20px;
    height: 40px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px #000;
    border-radius: 5px;
    background-color: #f4f3f5;
}

.pop-up-block-16 {
    font-size: 16px;
    font-weight: 550;
}

.pop-up-block-17 {
    max-height: 200px;
    height: 200px;
    margin: 14px 0px;
    width: 95%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-y: auto;
}

.pop-up-block-18{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    margin: 0px 8px 8px 8px;
    padding: 5px 5px;
    height: 40px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pop-up-block-18-text{
    width: 100%;
    text-align: center;
}

.pop-up-block-19{
    font-size: 15px;
}

.pop-up-block-1 {
    margin-right: 15px;
    margin-top: 15px;
    cursor: pointer;
    opacity: 0.7;
}

.pop-up-block-1:hover {
    opacity: 1;
}

.pop-up-block-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.pop-up-block-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 60px;
    margin: 20px 20px;
    border: 1px solid rgb(48, 48, 48);
    border-radius: 5px;
    opacity: 0.7;
}

.pop-up-block-4:hover {
    opacity: 1;
    cursor: pointer;
}

.pop-up-block-5 {
    font-size: 17px;
    font-weight: 700;
    margin-left: 10px;
}

.wrap-label {
    min-width: 300px;
}

.label-information {
    padding: 10px 10px;
    margin-left: auto;
    align-items: center;
    display: flex;
    justify-content: center;
}

.toggle-group {
    display: flex;
    align-items: center;
    position: relative;

}

.toggle-group input[type=checkbox] {
    position: absolute;
    left: 10px;
    display: none;
}

.toggle-group input[type=checkbox]:checked~.onoffswitch .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.toggle-group input[type=checkbox]:checked~.onoffswitch .onoffswitch-label .onoffswitch-switch {
    right: 1px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.toggle-group input[type=checkbox]:focus~.onoffswitch {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
}

.regProxy-label {
    cursor: pointer;
    text-align: left;
    margin-right: 20px;
    font-size: 21px;
    font-weight: 500;
    line-height: 35px;
}

.proxy-label {
    cursor: pointer;
    text-align: left;
    margin-right: 20px;
    font-size: 17px;
    font-weight: 500;
    line-height: 35px;
    margin-bottom: 0px;
}

/* .toggle-group label:before {
  content: '';
  width: 100%;
  height: 40px;
  position: absolute;
  right: 0;
  top: -8px;
  z-index: 1;
} */

.toggle-group input[type=checkbox]:checked~.onoffswitch .onoffswitch-label .onoffswitch-inner {
    margin-left: 0px;
}

.page-content-body {
    margin: 20px 0 0;
}

.toggle-group input[type=checkbox]:checked~.onoffswitch .onoffswitch-label .onoffswitch-switch {
    right: 1px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.toggle-group input[type=checkbox]:focus~.onoffswitch {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
}

.onoffswitch {
    position: relative;
    max-width: 58px;
}

.onoffswitch .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    margin: 0;
}

.onoffswitch .onoffswitch-inner {
    width: 200%;
    margin-left: -100%;
    -webkit-transition: margin 0.15s ease-in-out;
    -o-transition: margin 0.15s ease-in-out;
    -moz-transition: margin 0.15s ease-in-out;
    transition: margin 0.15s ease-in-out;
}

.onoffswitch .onoffswitch-inner:before,
.onoffswitch .onoffswitch-inner:after {
    float: left;
    width: 50%;
    height: 24px;
    padding: 0;
    line-height: 24px;
    font-size: 80%;
    color: hsl(0, 0%, 100%);
    font-weight: normal;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.onoffswitch .onoffswitch-inner:before {
    content: "ON";
    background-color: #2cbae0;
    color: #fff;
    line-height: 26px;
    padding-right: 6px;
}

.onoffswitch .onoffswitch-inner:after {
    content: "OFF";
    padding-right: 14px;
    background-color: #D4F1F8;
    color: rgb(99, 114, 129);
    text-align: right;
    line-height: 26px;
}

.onoffswitch .onoffswitch-switch {
    width: 20px;
    height: 20px;
    margin: 0;
    position: absolute;
    top: 2px;
    bottom: 0;
    right: 36px;
    -webkit-transition: right 0.15s ease-in-out;
    -o-transition: right 0.15s ease-in-out;
    -moz-transition: right 0.15s ease-in-out;
    transition: right 0.15s ease-in-out;

    background: #FFFFFF;
    box-shadow: -1px 0 1px 0 rgba(0, 0, 0, 0.10), 0 1px 1px 0 rgba(0, 0, 0, 0.20);
    border-radius: 20px;
}

.div-block-50 {
    width: 90%;
    height: auto;
    max-width: 900px;
    margin: 0px auto 0px;
    padding: 4px 4px;
    text-align: left;
    display: flex;
}

.div-block-51 {
    width: 200px;
    height: 50px;
    margin: 0px 3px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.div-block-51:hover {
    border-top: 3px solid #2cbae0;
    color: #1d7d97;
}

.div-block-51.active {
    border-top: 3px solid #2cbae0;
    color: #1d7d97;
}

.div-block-52 {
    font-size: 18px;
    font-weight: 500;
    padding-top: 13px;
    line-height: 1.5;
    padding-bottom: 13px;
    color: #222129;
}

.div-block-53 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 1200px;
    margin-right: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.area-char-main {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.area-char-main-title {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.area-char-main-title-text{
    margin: 0px 30px 40px 40px;
    font-size: 15px;
    font-weight: 600;
}

.area-char-main-title-text b {
    margin-left: 7px;
    font-size: 20px;
    letter-spacing: 1px;
    color: #2cbae0;
    font-weight: 800;
}

@media (max-width: 991px) {
    .button {
        margin-top: -81px;
        margin-left: 21px;
    }

    .container {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
    }

    .container.empty {
        display: block;
        width: auto;
        max-width: 100%;
        padding-top: 0px;
        padding-right: 20px;
        padding-left: 30px;
    }

    .container.empty {
        display: inline-block;
        width: 100%;
        max-width: 100%;
        padding-top: 0px;
        padding-right: 20px;
        padding-left: 30px;
    }

    ._50 {
        width: 100%;
        padding-right: 0px;
    }

    .col {
        padding-right: 1px;
    }

    .modals {
        padding-right: 30px;
        padding-left: 30px;
    }

    .navbar {
        position: absolute;
        width: 100%;
        height: auto;
        background-color: rgba(255, 255, 255, 0);
    }

    .logo {
        float: left;
    }

    .menu-button {
        margin-top: 0px;
        margin-right: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        font-size: 35px;
    }

    .menu-button.w--open {
        background-color: #ececee;
    }

    .menu-button.w--open:focus {
        background-color: #414142;
    }

    .table-row {
        padding-left: 10px;
    }

    .table-row.footer-row {
        padding-left: 10px;
    }

    .table-row.footer-row {
        padding-left: 10px;
    }

    .colour-details-div {
        min-height: 130px;
    }

    .hyperlink {
        display: inline-block;
    }

    .paragraph {
        font-family: Roboto, sans-serif;
    }

    .left-hand-nav {
        position: static;
        display: block;
        width: 100%;
        min-height: auto;
        margin-top: 85px;
        margin-bottom: 40px;
        padding: 40px 30px 30px;
    }

    .main-container {
        width: 100%;
        margin-left: 0%;
        float: none;
    }

    .lhn-links {
        width: auto;
        padding-right: 15px;
        padding-left: 15px;
        float: left;
        background-color: transparent;
        font-size: 14px;
    }

    .lhn-links:hover {
        padding-right: 15px;
        padding-left: 15px;
        border-left-style: none;
        background-color: transparent;
    }

    .lhn-links.w--current {
        display: block;
        min-height: auto;
        padding-right: 15px;
        padding-left: 15px;
        float: left;
    }

    .lhn-links.w--current:hover {
        padding-left: 15px;
    }

    .card {
        width: 100%;
    }

    .hero {
        margin-top: 0px;
        margin-bottom: -105px;
        padding-right: 40px;
        padding-left: 40px;
    }

    .hero-heading {
        display: block;
        width: auto;
        margin-right: auto;
        margin-left: auto;
        float: none;
    }

    .main-content {
        width: 100%;
        padding-top: 82px;
        padding-bottom: 40px;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: #fff;
    }

    .main-content-iframe {
        width: 95%;
        height: 400px;
    }

    .logo-text {
        padding-left: 10px;
    }

    .logo-text.w--current {
        display: inline-block;
        margin-top: 25px;
        padding-top: 0px;
        padding-left: 20px;
    }

    .search {
        width: 33.3%;
    }

    .nav-menu {
        margin-bottom: 0px;
        margin-left: auto;
        float: right;
        background-color: #fff;
    }

    .segmented-pill {
        width: 33.3%;
        text-align: center;
    }

    .dashboard-cols {
        margin-bottom: 15px;
    }

    .dashboard-card {
        min-height: auto;
        margin-bottom: 20px;
        padding-bottom: 30px;
    }

    .dashboard-card.small {
        margin-bottom: 20px;
    }

    .full-container {
        padding-top: 110px;
        padding-right: 30px;
        padding-left: 30px;
    }

    .full-container.help {
        padding-right: 30px;
        padding-left: 30px;
    }

    .full-container.help-hero {
        padding-right: 40px;
        padding-bottom: 70px;
        padding-left: 40px;
    }

    .pie-chart {
        width: 30vw;
        height: 30vw;
    }

    .help-center-hero {
        margin-top: 0px;
    }

    .list-container {
        margin-left: 0px;
    }

    .nav-link {
        width: 49%;
        margin-right: 1%;
        float: left;
        text-align: center;
    }

    .nav-link.w--current {
        width: 100%;
        text-align: center;
    }

    .tab-pane {
        padding-right: 0px;
        padding-left: 0px;
    }

    .icon-2 {
        color: #696969;
    }

    .left {
        margin-bottom: 20px;
        float: none;
        text-align: center;
    }

    .right {
        float: none;
        text-align: center;
    }

    .list-container-2.extra-padding {
        margin-left: 0px;
    }

    .list-container-2.extra-padding {
        margin-left: 0px;
    }

    .list-details-3 {
        padding-bottom: 24px;
    }

    .inbox-left-col {
        padding-right: 0px;
    }

    .inbox-center-div {
        padding-right: 0px;
        padding-left: 0px;
    }

    .image {
        margin-bottom: 0px;
        margin-left: 20px;
    }

    .container-2 {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
    }

    .text-block-10 {
        margin-left: -53px;
    }

    .heading-2 {
        margin-top: 80px;
    }

    .premium-button {
        margin-left: 2px;
    }

    .button-copy {
        margin-left: 2px;
    }

    .image-4 {
        margin-left: -4px;
    }

    .image-5 {
        margin-left: 4px;
    }

    .button-copy {
        margin-left: 2px;
    }

    .button-copy-copy {
        margin-left: 2px;
    }

    .super-copy-copy {
        margin-left: 2px;
    }

    .claim-button {
        margin-left: 2px;
    }

    .button-copy {
        margin-top: -81px;
        margin-left: 21px;
    }

    .home-table {
        width: 95%;
    }

    .table100 th {
        font-size: 14px;
        font-weight: 500;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .column2 {
        width: 60px;
    }

    .column3 {
        width: 150px;
    }

    .column3.exchange {
        width: 200px;
    }

    .column4 {
        width: 120px;
    }

    .column5 {
        width: 120px;
    }

    .column6 {
        width: 120px;
    }

    .column7 {
        width: 120px;
    }

    .column8 {
        width: 120px;
    }

    .column9 {
        width: 150px;
    }

    .column9.exchange {
        width: 100px;
    }

    .row-addition1{
        width: 210px;
        max-width: 210px;
    }
    
    .row-addition2{
        width: 240px;
        max-width: 240px;
    }
    
    .row-addition3{
        width: 360px;
        max-width: 360px;
    }
    
    .row-addition4{
        width: 150px;
        max-width: 150px;
    }

    ._1 {
        width: 5%;
    }

    .heading1 {
        margin-left: -27px;
    }

    ._8.buttonvote {
        width: 12%;
    }

    .refund-button {
        margin-top: -81px;
        margin-left: 21px;
    }

    .fund {
        margin-top: -81px;
        margin-left: 21px;
    }

    .button-copy {
        margin-top: -81px;
        margin-left: 21px;
    }

    ._50-copy {
        width: 100%;
        padding-right: 0px;
    }

    .fund-copy {
        margin-top: -81px;
        margin-left: 21px;
    }

    .fund-copy-copy {
        margin-top: -81px;
        margin-left: 21px;
    }

    .update1 {
        margin-top: -81px;
        margin-left: 21px;
    }

    .update-copy {
        margin-top: -81px;
        margin-left: 21px;
    }

    .update1-copy {
        margin-top: -81px;
        margin-left: 21px;
    }

    .button-copy-copy {
        margin-top: -81px;
        margin-left: 21px;
    }

    .div-block-5 {
        margin-right: 23px;
        margin-left: 23px;
    }

    .div-block-6 {
        padding-top: 0px;
    }

    .heading-5._22 {
        width: 100%;
        font-size: 21px;
    }

    .heading-5.bottom {
        line-height: 30px;
    }

    .text-block-13.centre.correct {
        width: auto;
    }

    .div-block-7.h {
        display: none;
        overflow: hidden;
    }

    .div-block-9 {
        padding-top: 0px;
    }

    .text-field {
        margin-left: 6px;
    }

    .div-block-16 {
        width: 328px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .image-6 {
        display: block;
    }

    .image-7 {
        margin-bottom: -106px;
        margin-left: 48px;
    }

    .nav-menu-2 {
        margin-left: auto;
        float: right;
        background-color: #414142;
    }

    .lhn-links-2 {
        width: auto;
        padding-right: 15px;
        padding-left: 15px;
        float: left;
        background-color: transparent;
        font-size: 14px;
    }

    .lhn-links-2:hover {
        padding-right: 15px;
        padding-left: 15px;
        border-left-style: none;
        background-color: transparent;
    }

    .lhn-links-2.w--current {
        display: block;
        float: left;
    }

    .lhn-links-2.w--current:hover {
        padding-left: 25px;
    }

    .navbar-2 {
        position: absolute;
        width: 100%;
        height: auto;
        background-color: rgba(65, 65, 66, 0.85);
    }

    .menu-button-2 {
        margin-top: 0px;
        margin-right: -20px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        font-size: 35px;
    }

    .menu-button-2.w--open:focus {
        background-color: #414142;
    }

    .section-5 {
        padding-right: 15px;
        padding-left: 15px;
    }

    .newnav {
        padding-right: 15px;
        padding-left: 15px;
    }

    .main-content-proxydash {
        width: 100%;
        padding-top: 82px;
        padding-bottom: 40px;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: #fff;
    }

    .div-block-16-copy {
        width: 328px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .heading-5-copy.bottom {
        line-height: 30px;
    }

    .proxy-table {
        width: 100%;
    }

    .proxyinternal {
        width: 100%;
    }

    .div-block-11-copy {
        border-top-style: none;
        padding-top: 44px;
    }

    .listdiv {
        margin-right: auto;
        margin-left: auto;
    }

    .listheading.bottom {
        line-height: 30px;
    }

    .text-block-13-copy.centre.correct {
        width: auto;
    }

    .divinfo {
        width: 10%;
    }

    .link-6 {
        margin-left: -21px;
    }

    .brand-logo {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1;
    }

    .footer-2 {
        padding-right: 20px;
        padding-left: 20px;
    }

    .heading-8 {
        margin-left: 28px;
    }

    .proxyinternal-copy {
        width: 95%;
    }

    .bpInternal-table {
        width: 95%;
    }

    .column1-regBp {
        width: 150px;
    }

    .column2-regBp {
        width: 160px;
    }

    .column3-regBp {
        width: 160px;
    }

    .column4-regBp {
        width: 250px;
    }

    .column5-regBp {
        width: 120px;
    }

    .column6-regBp {
        min-width: 200px;
        padding-left: 10px;
    }

    .column7-regBp {
        min-width: 50px;
    }

    .tab-link-tab-2.left {
        margin-bottom: 0px;
    }

    .addbutton {
        margin-top: -12px;
        margin-left: 0px;
    }

    .listproxyheading.bottom {
        line-height: 30px;
    }

    .fundtittle.bottom {
        line-height: 30px;
    }

    .heading-5-copy.bottom {
        line-height: 30px;
    }

    .heading-5-copy-copy.bottom {
        line-height: 30px;
    }

    .text-block-13-copy.centre.correct {
        width: auto;
    }

    .text-block-13-copy-copy.centre.correct {
        width: auto;
    }

    .bpregisterbutton {
        margin-top: -12px;
        margin-left: 0px;
    }

    .bptextbox.centre.correct {
        width: auto;
    }

    .lang-wrap {
        position: absolute;
        /* left: 0%; */
        top: 40px;
        right: -26px;
        /* right: auto; */
        bottom: auto;
        display: none;
        width: 130px;
        z-index: 50;
    }

    .div-block-14 {
        flex-wrap: wrap;
        width: 80%;
    }

    .main-content-infor-1{
        margin: 0px 15px;
    }

    .main-content-infor-2{
        padding: 20px 30px;
        font-size: 15px;
        width: 220px;
    }
    
    .main-content-infor-3{
        margin-top: 14px;
        font-size: 18px;
        font-weight: 500;
    }
}

@media (max-width: 767px) {
    h2 {
        margin-bottom: -10px;
        padding-top: 7px;
    }

    h3 {
        margin-bottom: -6px;
        padding-top: 6px;
    }

    h4 {
        margin-bottom: -8px;
        padding-top: 8px;
    }

    p {
        margin-top: 10px;
        line-height: 30px;
    }

    .button {
        width: 100%;
        margin-top: -59px;
        margin-left: 0px;
    }

    .section-header {
        display: block;
    }

    .section-header.extra-under {
        margin-bottom: 25px;
        padding-top: 5px;
    }

    .container.empty {
        position: relative;
        display: block;
        margin-left: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .container.empty {
        padding-right: 0px;
        padding-left: 30px;
    }

    .col {
        padding-right: 10px;
    }

    .text2 {
        padding-left: 0px;
        border-top: 1px none #000;
        border-right: 1px none #000;
        border-left: 1px none #000;
        background-color: transparent;
    }

    .text2:focus {
        border-color: #69d2c3;
    }

    .modal-box {
        padding: 20px;
    }

    .modal-box.login {
        padding-top: 10px;
        padding-right: 30px;
        padding-left: 30px;
    }

    .navbar {
        position: absolute;
    }

    .logo {
        min-height: auto;
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .select-field:focus {
        border-color: #69d2c3;
    }

    .menu-button {
        position: relative;
        display: block;
        margin-right: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #e6e6e6;
        color: #e9e9e9;
    }

    .menu-button.w--open {
        background-color: #414142;
    }

    .colour-palette-div {
        width: 100px;
        height: 100px;
        margin-bottom: 0px;
    }

    .colour-palette-div.sky {
        width: 100px;
    }

    .spacer {
        height: 50px;
    }

    .tab {
        width: 100%;
        margin-bottom: 10px;
        padding-right: 0px;
        padding-bottom: 15px;
        padding-left: 0px;
    }

    .tab.w--current {
        width: 100%;
        margin-bottom: 10px;
        padding-right: 0px;
        padding-bottom: 15px;
        padding-left: 0px;
    }

    .tabs-content {
        position: relative;
        width: 100%;
        padding: 0px;
    }

    .list-heading {
        margin-left: -18px;
    }

    .table-div {
        position: relative;
        display: block;
        overflow: scroll;
        width: 100%;
        min-height: 60vh;
        grid-auto-columns: 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
    }

    .table-row {
        width: 150%;
        padding-left: 0px;
    }

    .table-row.footer-row {
        padding-left: 0px;
    }

    .table-row.footer-row {
        padding-left: 0px;
    }

    .table-col {
        padding-left: 0px;
    }

    .colour-details-div {
        min-height: 150px;
    }

    .according-panel {
        margin-right: -20px;
        margin-left: -20px;
    }

    .h1 {
        margin-bottom: 20px;
    }

    .h3 {
        margin-bottom: 8px;
    }

    .left-hand-nav {
        position: relative;
        width: 100%;
        min-height: auto;
    }

    .main-container {
        width: 100%;
        margin-left: 0%;
    }

    .lhn-links {
        display: block;
        width: 100%;
        min-height: auto;
        padding: 25px 30px;
        float: none;
        border-bottom-style: none;
        line-height: 30px;
        text-align: left;
    }

    .lhn-links:hover {
        padding: 25px 30px 25px 27px;
        border-top-style: none;
        border-left-style: solid;
    }

    .lhn-links.w--current {
        display: inline-block;
        width: 100%;
        padding-top: 25px;
        padding-right: 30px;
        padding-left: 23px;
        float: none;
        border-top-style: none;
        border-left-style: solid;
    }

    .lhn-links.w--current:hover {
        padding-top: 25px;
        padding-left: 23px;
    }

    .card {
        width: 100%;
        min-height: auto;
    }

    .hero {
        margin-bottom: -65px;
    }

    .hero-heading {
        font-size: 60px;
    }

    .main-content {
        display: block;
        height: auto;
        padding-top: 42px;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .main-content-iframe {
        width: 99%;
        height: 280px;
    }

    .search {
        width: 100%;
        margin-top: 0px;
        margin-right: 0px;
        float: right;
    }

    .search-input {
        display: block;
        margin-right: auto;
        margin-left: auto;
        float: none;
    }

    .search-button.help-button {
        background-color: transparent;
    }

    .nav-menu {
        display: block;
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        float: left;
        background-color: #e6e6e6;
    }

    .chat-container {
        max-height: 80vh;
        padding-right: 0px;
        padding-left: 0px;
    }

    .dashboard-card.small {
        margin-bottom: 20px;
    }

    .full-container {
        padding-right: 30px;
        padding-left: 30px;
    }

    .full-container.help {
        padding-right: 20px;
        padding-left: 20px;
    }

    .full-container.help-hero {
        padding-right: 30px;
        padding-left: 30px;
    }

    .pie-chart {
        width: 30vw;
        height: 30vw;
    }

    .progress-bar {
        margin-top: 15px;
    }

    .preview-thumb {
        padding-top: 20px;
    }

    .circle-avatar {
        margin-bottom: 30px;
    }

    .chat-popup {
        width: 100%;
        height: auto;
        min-height: 100vh;
        margin-right: 0px;
        margin-bottom: 0px;
        padding-top: 70px;
    }

    .chat-text-input {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        padding-left: 20px;
    }

    .tiles-container {
        padding-right: 20px;
        padding-left: 20px;
    }

    .hero-subheading {
        font-size: 35px;
    }

    .tab-pane {
        padding-right: 30px;
        padding-left: 30px;
        float: none;
    }

    .tab-pane.list {
        padding: 0px;
    }

    .icon-2 {
        font-family: Roboto, sans-serif;
        color: #858585;
        font-weight: 300;
    }

    .time-stamp {
        margin-top: -56px;
    }

    .list-details-3 {
        margin-left: 0px;
        padding-top: 0px;
    }

    .table-scroll {
        height: 100vh;
    }

    .tab-pane-table {
        padding-right: 30px;
        padding-left: 30px;
    }

    .tab-pane-form {
        padding: 30px;
    }

    .table-div-2 {
        position: relative;
        display: block;
        overflow: scroll;
        width: 100%;
        min-height: 60vh;
        grid-auto-columns: 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
    }

    .tabs-content-3 {
        position: relative;
        width: 100%;
        padding: 0px;
    }

    .text-block-10 {
        margin-top: 11px;
        margin-left: 3px;
    }

    .premium-button {
        width: 100%;
        font-size: 15px;
    }

    .image-3 {
        margin-left: 9px;
    }

    .button-copy {
        width: 100%;
    }

    .image-5 {
        margin-left: 8px;
    }

    .button-copy {
        width: 100%;
    }

    .button-copy-copy {
        width: 100%;
        margin-left: -7px;
    }

    .super-copy-copy {
        width: 100%;
        margin-left: -3px;
    }

    .claim-button {
        width: 100%;
    }

    .button-copy {
        width: 100%;
        margin-top: -59px;
        margin-left: 0px;
    }

    ._1 {
        display: none;
    }

    ._2 {
        width: 27%;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    ._3 {
        width: 13%;
    }

    ._5 {
        width: 14%;
    }

    ._6 {
        display: none;
        overflow: visible;
    }

    ._7 {
        width: 9%;
    }

    .refund-button {
        width: 100%;
        margin-top: -59px;
        margin-left: 0px;
    }

    .fund {
        width: 100%;
        margin-top: -59px;
        margin-left: 0px;
    }

    .button-copy {
        width: 100%;
        margin-top: -59px;
        margin-left: 0px;
    }

    .modal-box-copy {
        padding: 20px;
    }

    .modal-box-copy.login {
        padding-top: 10px;
        padding-right: 30px;
        padding-left: 30px;
    }

    .modal-box-copy.login-copy {
        padding-top: 10px;
        padding-right: 30px;
        padding-left: 30px;
    }

    .fund-copy {
        width: 100%;
        margin-top: -59px;
        margin-left: 0px;
    }

    .fund-copy-copy {
        width: 100%;
        margin-top: -59px;
        margin-left: 0px;
    }

    .text-field-copy {
        padding-left: 0px;
        border-top: 1px none #000;
        border-right: 1px none #000;
        border-left: 1px none #000;
        background-color: transparent;
    }

    .text-field-copy:focus {
        border-color: #69d2c3;
    }

    .text-field-copy {
        padding-left: 0px;
        border-top: 1px none #000;
        border-right: 1px none #000;
        border-left: 1px none #000;
        background-color: transparent;
    }

    .text-field-copy:focus {
        border-color: #69d2c3;
    }

    .text-field-copy-copy {
        padding-left: 0px;
        border-top: 1px none #000;
        border-right: 1px none #000;
        border-left: 1px none #000;
        background-color: transparent;
    }

    .text-field-copy-copy:focus {
        border-color: #69d2c3;
    }

    .update1 {
        width: 100%;
        margin-top: -59px;
        margin-left: 0px;
    }

    .text-field-copy-copy-copy {
        padding-left: 0px;
        border-top: 1px none #000;
        border-right: 1px none #000;
        border-left: 1px none #000;
        background-color: transparent;
    }

    .text-field-copy-copy-copy:focus {
        border-color: #69d2c3;
    }

    .text-field-copy-copy-copy-copy {
        padding-left: 0px;
        border-top: 1px none #000;
        border-right: 1px none #000;
        border-left: 1px none #000;
        background-color: transparent;
    }

    .text-field-copy-copy-copy-copy:focus {
        border-color: #69d2c3;
    }

    .text-field-copy-copy-copy-copy-copy {
        padding-left: 0px;
        border-top: 1px none #000;
        border-right: 1px none #000;
        border-left: 1px none #000;
        background-color: transparent;
    }

    .text-field-copy-copy-copy-copy-copy:focus {
        border-color: #69d2c3;
    }

    .text-field-copy-copy-copy-copy-copy-copy {
        padding-left: 0px;
        border-top: 1px none #000;
        border-right: 1px none #000;
        border-left: 1px none #000;
        background-color: transparent;
    }

    .text-field-copy-copy-copy-copy-copy-copy:focus {
        border-color: #69d2c3;
    }

    .update-copy {
        width: 100%;
        margin-top: -59px;
        margin-left: 0px;
    }

    .update1-copy {
        width: 100%;
        margin-top: -59px;
        margin-left: 0px;
    }

    .text2-copy {
        padding-left: 0px;
        border-top: 1px none #000;
        border-right: 1px none #000;
        border-left: 1px none #000;
        background-color: transparent;
    }

    .text2-copy:focus {
        border-color: #69d2c3;
    }

    .text2-copy {
        padding-left: 0px;
        border-top: 1px none #000;
        border-right: 1px none #000;
        border-left: 1px none #000;
        background-color: transparent;
    }

    .text2-copy:focus {
        border-color: #69d2c3;
    }

    .text2-copy-copy {
        padding-left: 0px;
        border-top: 1px none #000;
        border-right: 1px none #000;
        border-left: 1px none #000;
        background-color: transparent;
    }

    .text2-copy-copy:focus {
        border-color: #69d2c3;
    }

    .but {
        padding-left: 0px;
        border-top: 1px none #000;
        border-right: 1px none #000;
        border-left: 1px none #000;
        background-color: transparent;
    }

    .but:focus {
        border-color: #69d2c3;
    }

    .button-copy-copy {
        width: 100%;
        margin-top: -59px;
        margin-left: 0px;
    }

    .div-block-6 {
        padding-top: 0px;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .heading-5.sub {
        padding-top: 0px;
    }

    .heading-5._22.naay {
        width: 100%;
        margin-left: 0px;
        text-align: center;
    }

    .heading-5.sub2 {
        text-align: left;
    }

    .text-block-13.centre {
        width: auto;
        padding-right: 32px;
        padding-left: 32px;
    }

    .text-block-13.sub3 {
        margin-top: 0px;
    }

    .text-block-13.info {
        margin-left: 0px;
        text-align: left;
    }

    .div-block-7.h {
        display: none;
    }

    .div-block-7.h1 {
        display: none;
    }

    .div-block-9 {
        padding-top: 0px;
    }

    .div-block-12 {
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;
    }

    .div-block-14 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .div-block-17 {
        padding-top: 0px;
    }

    .image-6 {
        margin-top: 23px;
    }

    .section-3 {
        display: block;
        margin-top: 0px;
        padding-top: 50px;
        background-color: #fff;
    }

    .nav-menu-2 {
        width: 100%;
        margin-left: 0px;
        float: left;
        background-color: #414142;
    }

    .icon-3 {
        font-family: Roboto, sans-serif;
        color: #fff;
        font-weight: 300;
    }

    .lhn-links-2 {
        display: block;
        width: 100%;
        min-height: auto;
        padding: 25px 30px;
        float: none;
        border-bottom-style: none;
        line-height: 30px;
        text-align: left;
    }

    .lhn-links-2:hover {
        padding: 25px 30px 25px 27px;
        border-top-style: none;
        border-left-style: solid;
    }

    .lhn-links-2.w--current {
        display: inline-block;
        width: 100%;
        padding-top: 25px;
        padding-right: 30px;
        padding-left: 23px;
        float: none;
        border-top-style: none;
        border-left-style: solid;
    }

    .lhn-links-2.w--current:hover {
        padding-top: 25px;
        padding-left: 23px;
    }

    .navbar-2 {
        position: absolute;
    }

    .menu-button-2 {
        margin-right: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .menu-button-2.w--open {
        background-color: #414142;
    }

    .section-5 {
        padding-right: 15px;
        padding-left: 15px;
    }

    .link-block {
        padding-right: 11px;
        padding-left: 11px;
    }

    .newnav {
        padding-right: 15px;
        padding-left: 15px;
    }

    .main-content-proxydash {
        display: block;
        height: auto;
        padding-top: 42px;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .heading-5-copy.sub {
        padding-top: 0px;
    }

    .proxy-table.hide {
        display: none;
    }

    ._7-copy {
        width: 12%;
    }

    ._7-copy {
        width: 12%;
    }

    ._1-copy {
        display: none;
    }

    ._2-copy {
        width: 32%;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    ._3-copy {
        width: 13%;
    }

    ._5-copy {
        width: 17%;
    }

    .proxyrewards1 {
        display: none;
        overflow: visible;
    }

    ._6-copy {
        display: none;
        overflow: visible;
    }

    ._1-copy {
        display: none;
    }

    ._2-copy {
        width: 32%;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    ._3-copy {
        width: 13%;
    }

    ._5-copy {
        width: 17%;
    }

    .proxyrewards {
        display: none;
        overflow: visible;
    }

    ._6-copy {
        display: none;
        overflow: visible;
    }

    .proxyfee {
        display: none;
        overflow: visible;
    }

    .proxyfee1 {
        display: none;
        overflow: visible;
    }

    .row1internal {
        width: 32%;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .row2internal {
        width: 13%;
    }

    .row3internal {
        width: 17%;
    }

    .row4internal {
        display: none;
        overflow: visible;
    }

    .row4internal-checkbox {
        display: none;
        overflow: visible;
    }

    .row2internal1 {
        width: 32%;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .row2internal2 {
        width: 17%;
    }

    .row2internal3 {
        display: none;
        overflow: visible;
    }

    .row2internal3-header {
        display: none;
        overflow: visible;
    }

    .row2internal4 {
        display: none;
        overflow: visible;
    }

    .proxyrewards-copy {
        display: none;
        overflow: visible;
    }

    .proxyfee1-copy {
        display: none;
        overflow: visible;
    }

    .listheading.sub {
        padding-top: 0px;
    }

    .listheading._22-copy {
        width: 100%;
    }

    .text-block-13-copy.centre {
        width: auto;
        padding-right: 32px;
        padding-left: 32px;
    }

    .text-block-13-copy.sub3 {
        margin-top: 0px;
    }

    .divinfo {
        width: 12%;
    }

    .footer-copy {
        margin-right: -10px;
        margin-left: -10px;
    }

    .brand-logo.footer-logo {
        width: 100%;
        padding-left: 0px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .footer-copy-link {
        display: block;
        margin: 5px 0px;
        float: none;
        text-align: left;
    }

    .footer-copy-link.hint {
        text-align: center;
    }

    .footer-social {
        margin-right: 5px;
        margin-left: 5px;
    }

    .footer-social.facebook {
        margin-left: 0px;
    }

    .footer-social.twitter {
        margin-right: 5px;
    }

    .footer-nav-title {
        margin-top: 30px;
    }

    .footer-social-icons {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-top: 15px;
        margin-bottom: 10px;
        margin-left: -5px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: left;
    }

    .footer-2 {
        padding-right: 20px;
        padding-left: 20px;
    }

    .footer-copy-text {
        text-align: left;
    }

    .footer-copy-text.hint {
        text-align: center;
    }

    .image-10 {
        margin-top: 0px;
        margin-left: 0px;
    }

    .row2internal2-copy {
        width: 17%;
    }

    .row2internal1-copy {
        width: 32%;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .row2internal1-copy.down {
        margin-top: 15px;
    }

    .row2internal2-copy {
        width: 17%;
    }

    .tab-link-tab-2.side {
        background-color: rgba(255, 255, 255, 0);
        font-size: 14px;
    }

    .tab-link-tab-2.side:hover {
        border-right-style: none;
        background-color: #fff;
    }

    .tab-link-tab-2.side.w--current {
        border-right-style: none;
        background-color: #fff;
    }

    .tabs-3 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .tabs-menu-4 {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .tabs-content-4 {
        width: 100%;
        padding-top: 25px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .column {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .container-3 {
        padding-right: 25px;
        padding-left: 25px;
    }

    .div-block-25 {
        -ms-grid-columns: 0.25fr 0.75fr 0.5fr 0.5fr 0.5fr 0.5fr;
        grid-template-columns: 0.25fr 0.75fr 0.5fr 0.5fr 0.5fr 0.5fr;
    }

    .votebutton {
        margin-left: -29px;
    }

    .div-block-35 {
        width: 85%;
    }

    .footer-social-icons-2 {
        margin-bottom: 10px;
        margin-left: -5px;
        text-align: left;
    }

    .footer-nav-title-2 {
        margin-top: 30px;
    }

    .div-block-37 {
        width: 60%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .listproxyheading.sub {
        padding-top: 0px;
    }

    .listproxyheading._22.naay {
        width: 100%;
        margin-left: 0px;
        text-align: center;
    }

    .listproxyheading.sub2 {
        text-align: left;
    }

    .fundtittle.sub {
        padding-top: 0px;
    }

    .fundtittle._22.naay {
        width: 100%;
        margin-left: 0px;
        text-align: center;
    }

    .fundtittle.sub2 {
        text-align: left;
    }

    .heading-5-copy.sub {
        padding-top: 0px;
    }

    .heading-5-copy._22.naay {
        width: 100%;
        margin-left: 0px;
        text-align: center;
    }

    .heading-5-copy.sub2 {
        text-align: left;
    }

    .heading-5-copy-copy.sub {
        padding-top: 0px;
    }

    .heading-5-copy-copy._22.naay {
        width: 100%;
        margin-left: 0px;
        text-align: center;
    }

    .heading-5-copy-copy.sub2 {
        text-align: left;
    }

    .text-block-13-copy.centre {
        width: auto;
        padding-right: 32px;
        padding-left: 32px;
    }

    .text-block-13-copy.info {
        width: 100%;
    }

    .text-block-13-copy.sub3 {
        margin-top: 0px;
    }

    .text-block-13-copy.info {
        margin-left: 0px;
        text-align: left;
    }

    .howmanyeos {
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;
    }

    .text-block-13-copy-copy.centre {
        width: auto;
        padding-right: 32px;
        padding-left: 32px;
    }

    .text-block-13-copy-copy.sub3 {
        margin-top: 0px;
    }

    .bptextbox.centre {
        width: auto;
        padding-right: 32px;
        padding-left: 32px;
    }

    .bptextbox.sub3 {
        margin-top: 0px;
    }

    .bptextbox.info {
        margin-left: 0px;
        text-align: left;
    }

    .div-block-25-copy {
        -ms-grid-columns: 0.25fr 0.75fr 0.5fr 0.5fr 0.5fr 0.5fr;
        grid-template-columns: 0.25fr 0.75fr 0.5fr 0.5fr 0.5fr 0.5fr;
    }

    .table100 th {
        font-size: 14px;
        font-weight: 500;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .column2 {
        min-width: 50px;
    }

    .column3 {
        min-width: 150px;
    }

    .column4 {
        min-width: 120px;
    }

    .column5 {
        min-width: 120px;
    }

    .column6 {
        min-width: 120px;
    }

    .column7 {
        min-width: 120px;
    }

    .column8 {
        min-width: 120px;
    }

    .column9 {
        min-width: 150px;
    }

    .column9.exchange {
        min-width: 100px;
    }

    .column2-dashProxy {
        min-width: 80px;
    }

    .column3-dashProxy {
        min-width: 200px;
    }

    .column4-dashProxy {
        min-width: 140px;
    }

    .column6-dashProxy {
        min-width: 80px;
    }

    .column7-dashProxy {
        min-width: 120px;
    }

    .column2-dashBp {
        min-width: 80px;
    }

    .column3-dashBp {
        min-width: 180px;
    }

    .column4-dashBp {
        min-width: 130px;

    }

    .column5-dashBp {
        min-width: 80px;

    }

    .column6-dashBp {
        min-width: 80px;
    }

    .column7-dashBp {
        min-width: 190px;
    }

    .button-3-copy{
        padding: 20px 30px;
        border-radius: 11px;
    }
    .main-content-infor {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .main-content-infor-1{
        width: 80%;
        margin-bottom: 24px;
    }

    .main-content-infor-2{
        width: 300px;
    }

    .main-content-infor-3{
        margin-top: 7px;
    }
}

@media (max-width: 479px) {
    .button {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .section-header {
        font-size: 30px;
        line-height: 55px;
        text-align: center;
    }

    .container {
        padding-top: 0px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .container.empty {
        padding-left: 20px;
    }

    .container.empty {
        padding-left: 20px;
    }

    .col {
        padding-right: 0px;
    }

    .dropdown-list {
        padding-top: 0px;
    }

    .menu-button {
        background-color: #fff;
        color: #fff;
    }

    .menu-button.w--open {
        background-color: #e6e6e6;
    }

    .menu-button.w--open:focus {
        background-color: #fff;
    }

    .colour-palette-div {
        width: 60px;
        height: 60px;
    }

    .colour-palette-div.mint {
        width: 60px;
        height: 60px;
    }

    .colour-palette-div.sky {
        width: 60px;
    }

    .colour-row {
        margin-bottom: 0px;
    }

    .tab {
        position: relative;
        display: inline-block;
        width: 100%;
        margin-right: auto;
        margin-bottom: 10px;
        margin-left: auto;
        padding-bottom: 15px;
        float: none;
        text-align: center;
    }

    .tab.w--current {
        margin-bottom: 10px;
        padding-bottom: 15px;
        text-align: center;
    }

    .tabs-content {
        display: block;
        overflow: visible;
        padding-right: 0px;
        padding-left: 0px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .list-arrow {
        margin-left: -13px;
        padding-left: 9px;
    }

    .table-div {
        position: relative;
        display: block;
        overflow: scroll;
        min-height: 50vh;
    }

    .table-row {
        width: 250%;
    }

    .list-thumbnail.green {
        margin-right: 15px;
    }

    .list-thumbnail.yellow {
        margin-right: 15px;
    }

    .list-thumbnail.pink {
        margin-right: 15px;
    }

    .list-thumbnail.blue {
        margin-right: 15px;
    }

    .alert-box {
        padding-bottom: 30px;
    }

    .colour-details-div {
        margin-bottom: 0px;
        font-size: 14px;
    }

    .form {
        margin-right: -10px;
        margin-left: -10px;
    }

    .tabs-menu {
        position: relative;
        width: auto;
        height: auto;
    }

    .h1 {
        margin-bottom: 16px;
        margin-left: 0px;
    }

    .h1.light {
        font-size: 30px;
        line-height: 40px;
    }

    .lhn-links {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 14px;
    }

    .lhn-links:hover {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .panel {
        margin-left: -64px;
        padding-left: 0px;
    }

    .list-description {
        margin-left: 64px;
    }

    .hero {
        min-height: 90vh;
        margin-top: 0px;
        margin-bottom: -70px;
        padding: 120px 20px 80px;
    }

    .hero-heading {
        min-height: auto;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 40px;
        line-height: 50px;
    }

    .logo-text {
        margin-bottom: 15px;
        margin-left: 20px;
        padding-top: 25px;
        padding-left: 0px;
    }

    .logo-text.w--current {
        padding-top: 23px;
    }

    .icon-div {
        width: 32%;
        min-width: auto;
        margin-right: 1px;
        margin-bottom: 1px;
    }

    .search-button.help-button {
        padding-right: 25px;
        padding-left: 25px;
    }

    .segmented-pill {
        float: left;
    }

    .segmented-pill.w--current {
        float: left;
    }

    .segmented-pill.middle {
        width: 33.2%;
    }

    .segmented-pill.middle._50pc {
        width: 50%;
    }

    .segmented-pill.middle._50pc.w--current {
        width: 50%;
    }

    .chat-container {
        max-height: 80vh;
    }

    .dashboard-cols {
        padding-right: 0px;
        padding-left: 0px;
    }

    .dashboard-row {
        margin-top: 0px;
        margin-right: 0px;
        margin-left: 0px;
    }

    .dashboard-card {
        margin-bottom: 0px;
    }

    .dashboard-card.small {
        min-height: auto;
        margin-bottom: 0px;
    }

    .dashboard-card.short {
        padding-left: 20px;
    }

    .full-container {
        padding-right: 15px;
        padding-left: 15px;
    }

    .full-container.help {
        padding-right: 15px;
        padding-left: 15px;
    }

    .full-container.help-hero {
        padding-right: 25px;
        padding-bottom: 20px;
        padding-left: 25px;
    }

    .pie-chart {
        width: 70vw;
        height: 70vw;
    }

    .chat-popup {
        padding-right: 25px;
        padding-left: 25px;
    }

    .hero-subheading {
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 40px;
    }

    .nav-link {
        width: 100%;
        float: none;
    }

    .tab-pane {
        width: 100%;
        margin-left: -134px;
        padding-left: 0px;
    }

    .tab-pane.list {
        padding: 0px;
    }

    .list-heading-2 {
        margin-bottom: 8px;
        line-height: 24px;
    }

    .list-item-3 {
        padding-right: 16px;
        padding-left: 16px;
    }

    .overline {
        margin-bottom: 8px;
        margin-left: 16px;
    }

    .time-stamp {
        margin-top: -86px;
    }

    .tab-pane-table {
        width: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .columns {
        display: block;
    }

    .image {
        margin-top: -42px;
        margin-left: 0px;
    }

    .container-2 {
        padding-top: 0px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .table-div-2 {
        position: relative;
        display: block;
        overflow: scroll;
        min-height: 50vh;
    }

    .tabs-content-3 {
        display: block;
        overflow: visible;
        padding-right: 0px;
        padding-left: 0px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .grid {
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        grid-template-areas: "Area Meter";
        -ms-grid-columns: 1fr 16px 1fr;
        grid-template-columns: 1fr 1fr;
        -ms-grid-rows: auto 16px auto;
        grid-template-rows: auto auto;
    }

    .premium-button {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .button-copy {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .button-copy {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .button-copy-copy {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .super-copy-copy {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .claim-button {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .button-copy {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .home-table {
        margin-bottom: 82px;
    }

    .row {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .heading1 {
        font-size: 14px;
    }

    .heading1.highlight {
        margin-right: auto;
        margin-left: auto;
    }

    .body1.c.votea {
        color: #2cbae0;
    }

    .body1.highlight {
        margin-right: auto;
        margin-left: -58px;
    }

    ._2 {
        width: 126px;
    }

    ._2.nay {
        width: 126px;
    }

    ._3 {
        display: none;
    }

    ._5 {
        display: none;
    }

    ._7 {
        width: 22%;
        text-align: center;
    }

    ._8 {
        display: block;
        width: auto;
        height: auto;
        margin-left: 25px;
    }

    ._8.buttonvote {
        display: none;
    }

    .refund-button {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .fund {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .button-copy {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .fund-copy {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .fund-copy-copy {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .update1 {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .form-copy {
        margin-right: -10px;
        margin-left: -10px;
    }

    .update-copy {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .update1-copy {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .button-copy-copy {
        width: 100%;
        margin-right: 0px;
        margin-left: -10px;
    }

    .div-block-5 {
        width: auto;
        margin-right: 10px;
        margin-bottom: 24px;
        margin-left: 10px;
        text-align: center;
    }

    .div-block-6 {
        display: block;
        height: 0px;
    }

    .heading-5 {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    .heading-5.sub {
        padding-top: 0px;
        line-height: 32px;
    }

    .heading-5._22 {
        width: 100%;
        margin-top: -7px;
        /* margin-left: -96px; */
        font-size: 20px;
    }

    .heading-5.bottom {
        padding-top: 50px;
    }

    .heading-5._22-copy {
        font-size: 30px;
    }

    .text-block-13 {
        font-size: 16px;
        text-align: center;
    }

    .text-block-13.centre.correct {
        padding-bottom: 0px;
    }

    .text-block-13.info {
        margin-left: 1px;
        text-align: left;
    }

    .text-block-13.sub3 {
        text-align: center;
    }

    .button-3 {
        margin-right: auto;
        margin-left: auto;
    }
    .button-3-copy {
        margin-right: auto;
        margin-left: auto;
    }

    .div-block-8 {
        width: 320px;
        margin-right: auto;
        margin-left: auto;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .div-block-9 {
        padding-top: 0px;
    }

    .div-block-11 {
        padding-top: 44px;
        padding-bottom: 15px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .div-block-14.nay {
        width: 95%;
    }

    .div-block-12 {
        /* display: none; */
    }

    .rowreward {
        height: auto;
        padding-top: 18px;
        padding-bottom: 18px;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .section-2 {
        padding-bottom: 80px;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .section-3 {
        padding-top: 0px;
    }

    .image-7 {
        margin-top: -42px;
    }

    .lhn-links-2 {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 14px;
    }

    .lhn-links-2:hover {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .section-5 {
        padding-right: 0px;
        padding-left: 0px;
    }

    .image-8 {
        padding-top: 12px;
    }

    .link-block.mobile {
        height: 60px;
        padding-right: 0px;
        padding-left: 0px;
        background-color: #fff;
    }

    .div-block-18 {
        display: none;
    }

    .mobile-menu {
        position: absolute;
        display: none;
        width: 100%;
        height: auto;
        padding-top: 71px;
    }

    .mobile-menu-acitve {
        position: absolute;
        display: block;
        width: 100%;
        height: auto;
        padding-top: 71px;
    }

    .mobile-trigger {
        position: relative;
        z-index: 999;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 80px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .link-block-3 {
        padding-left: 9px;
    }

    .link-block-3.w--current {
        padding-left: 20px;
    }

    .newnav {
        padding-right: 0px;
        padding-left: 0px;
    }

    .heading-5-copy {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    .heading-5-copy.sub {
        padding-top: 0px;
        line-height: 32px;
    }

    .heading-5-copy._22 {
        font-size: 30px;
    }

    .heading-5-copy.bottom {
        padding-top: 50px;
    }

    .heading-5-copy._22-copy {
        font-size: 30px;
    }

    .proxy-table {
        margin-bottom: 82px;
    }

    .proxy-table.hide {
        display: block;
        margin-bottom: 25px;
    }

    ._7-copy {
        width: 22%;
    }

    ._7-copy {
        width: 22%;
    }

    .heading1-copy {
        font-size: 14px;
    }

    .body1-copy.c.votea {
        color: #2cbae0;
    }

    ._2-copy {
        width: 52%;
    }

    .heading1-copy {
        font-size: 14px;
    }

    .heading1-copy {
        font-size: 14px;
    }

    ._3-copy {
        display: none;
    }

    .heading1-copy {
        font-size: 14px;
    }

    ._5-copy {
        display: none;
    }

    .heading1-copy {
        font-size: 14px;
    }

    .heading1-copy {
        font-size: 14px;
    }

    .heading1-copy {
        font-size: 14px;
    }

    ._2-copy {
        width: 52%;
    }

    ._3-copy {
        display: none;
    }

    ._5-copy {
        display: none;
    }

    .proxyfee {
        display: none;
    }

    .body1-copy.c.votea {
        color: #2cbae0;
    }

    .body1-copy.c-copy.votea {
        color: #2cbae0;
    }

    .body1-copy.c-copy.votea-copy {
        margin-left: 118px;
        color: #2cbae0;
    }

    .body1-copy.c.votea {
        color: #2cbae0;
    }

    .body1-copy.a-copy {
        width: 70%;
        margin-left: 10px;
    }

    .proxystats {
        height: auto;
        padding-top: 18px;
        padding-bottom: 18px;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .proxyinternal {
        margin-bottom: 82px;
    }

    .rowinternal {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .rowinternal1 {
        height: auto;
        padding-top: 18px;
        padding-bottom: 18px;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .row1internal {
        width: 52%;
    }

    .row2internal {
        display: none;
    }

    .row3internal {
        display: none;
    }

    .headinginternal {
        font-size: 14px;
    }

    .row2internal1 {
        width: 52%;
    }

    .row2internal2 {
        display: none;
    }

    .internalbody.c.votea {
        color: #2cbae0;
    }

    .section-6 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .bprow {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .bprow1 {
        height: auto;
        padding-top: 18px;
        padding-bottom: 18px;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .bprow2 {
        height: auto;
        padding-top: 18px;
        padding-bottom: 18px;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .bpbody.c.votea {
        color: #2cbae0;
    }

    .bpbody.c-copy.votea {
        color: #2cbae0;
    }

    .bpbody2.c.votea {
        color: #2cbae0;
    }

    .div-block-11-copy {
        padding-top: 44px;
        padding-bottom: 15px;
    }

    .button4 {
        margin-right: auto;
        margin-left: auto;
    }

    .bpbody1.c.votea {
        color: #2cbae0;
    }

    .actionsdiv {
        display: none;
    }

    .listdiv {
        display: block;
    }

    .listheading {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    .listheading.sub {
        padding-top: 0px;
        line-height: 32px;
    }

    .listheading._22 {
        font-size: 30px;
    }

    .listheading.bottom {
        padding-top: 50px;
    }

    .listheading._22-copy {
        font-size: 30px;
    }

    .listheading._22-copy {
        width: auto;
        font-size: 30px;
        line-height: 42px;
        text-align: center;
    }

    .text-block-13-copy {
        font-size: 16px;
        text-align: center;
    }

    .text-block-13-copy.centre.correct {
        padding-bottom: 0px;
    }

    .text-block-13-copy.sub3 {
        text-align: center;
    }

    .divinfo {
        width: 72px;
    }

    .rowrewarddiv {
        height: auto;
        padding-top: 17px;
        padding-bottom: 17px;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .link-6 {
        margin-left: -13px;
    }

    .footer-social.facebook {
        margin-left: 95px;
    }

    .footer-social-icons {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 0px;
        margin-left: 0px;
    }

    .image-10 {
        margin-left: 0px;
    }

    .row2internal2-copy {
        /* display: none; */
    }

    .heading-8 {
        margin-left: 8px;
    }

    .proxyinternal-copy {
        margin-top: 10px;
        margin-bottom: 50px;
    }

    .bpInternal-table {
        margin-top: 10px;
        margin-bottom: 50px;
    }

    .column1-regBp {
        min-width: 120px;
    }

    .column2-regBp {
        min-width: 130px;
    }

    .column3-regBp {
        min-width: 130px;
    }

    .column4-regBp {
        min-width: 200px;
    }

    .column5-regBp {
        min-width: 120px;
    }

    .column6-regBp {
        min-width: 200px;
    }

    .regbobod.c.votea {
        color: #2cbae0;
    }

    .regbobod.highlight {
        margin-right: auto;
        margin-left: auto;
    }

    .regbobod1.c.votea {
        color: #2cbae0;
    }

    .regbobod1.highlight {
        margin-right: auto;
        margin-left: auto;
    }

    .regbobod2.c.votea {
        color: #2cbae0;
    }

    .regbobod2.highlight {
        margin-right: auto;
        margin-left: auto;
    }

    .row2internal1-copy {
        /* display: none; */
        overflow: visible;
        width: 30%;
    }

    .row2internal1-copy.down {
        margin-top: 10px;
    }

    .row2internal2-copy {
        /* display: none; */
    }

    .tab-link-tab-2.right {
        font-size: 14px;
        line-height: 30px;
    }

    .tab-link-tab-2.right.w--current {
        font-size: 14px;
    }

    .tab-link-tab-2.left {
        font-size: 14px;
        line-height: 30px;
    }

    .tab-link-tab-2.left.w--current {
        line-height: 30px;
    }

    .tab-link-tab-2.side {
        padding-right: 15px;
        padding-left: 15px;
        font-size: 13px;
        line-height: 22px;
    }

    .tabs-3 {
        height: auto;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
    }

    .tabs-menu-4 {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-align-content: flex-end;
        -ms-flex-line-pack: end;
        align-content: flex-end;
    }

    .text-block-18 {
        line-height: 22px;
    }

    .div-block-25 {
        display: none;
        overflow: visible;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        justify-items: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        grid-auto-flow: row;
        grid-auto-columns: 0.25fr;
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        -ms-grid-columns: 0.25fr 1fr;
        grid-template-columns: 0.25fr 1fr;
        -ms-grid-rows: auto auto auto auto;
        grid-template-rows: auto auto auto auto;
    }

    .votebutton {
        margin-left: -1px;
    }

    .body1-copy-copy.c.votea {
        color: #2cbae0;
    }

    .body1-copy-copy.a-copy {
        width: 70%;
        margin-left: 10px;
    }

    .body1-copy-copy.a-copy-copy {
        width: 70%;
        margin-left: 10px;
    }

    .heading-10 {
        margin-top: 1px;
        margin-bottom: 13px;
        margin-left: 51px;
    }

    .image-11 {
        margin-top: -48px;
        margin-bottom: -13px;
        margin-left: 86px;
    }

    .div-block-35 {
        display: none;
    }

    .image-13 {
        margin-left: 88px;
    }

    .heading1-2 {
        font-size: 14px;
    }

    .heading1-2.highlight {
        margin-right: auto;
        margin-left: -74px;
    }

    .div-block-36 {
        padding-top: 44px;
        padding-bottom: 15px;
    }

    .link-11 {
        margin-left: 3px;
    }

    .listproxyheading {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    .listproxyheading.sub {
        padding-top: 0px;
        line-height: 32px;
    }

    .listproxyheading.sub2 {
        margin-left: 39px;
    }

    .listproxyheading._22 {
        font-size: 30px;
    }

    .listproxyheading.bottom {
        padding-top: 50px;
    }

    .listproxyheading._22-copy {
        font-size: 30px;
    }

    .fundtittle {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    .fundtittle.sub {
        padding-top: 0px;
        line-height: 32px;
    }

    .fundtittle.sub2 {
        margin-left: 35px;
    }

    .fundtittle._22 {
        font-size: 30px;
    }

    .fundtittle.bottom {
        padding-top: 50px;
    }

    .fundtittle._22-copy {
        font-size: 30px;
    }

    .heading-5-copy {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    .heading-5-copy.sub {
        padding-top: 0px;
        line-height: 32px;
    }

    .heading-5-copy._22 {
        font-size: 30px;
    }

    .heading-5-copy.bottom {
        padding-top: 50px;
    }

    .heading-5-copy._22-copy {
        font-size: 30px;
    }

    .heading-5-copy-copy {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    .heading-5-copy-copy.sub {
        padding-top: 0px;
        line-height: 32px;
    }

    .heading-5-copy-copy.sub2 {
        margin-left: 88px;
    }

    .heading-5-copy-copy._22 {
        font-size: 30px;
    }

    .heading-5-copy-copy.bottom {
        padding-top: 50px;
    }

    .heading-5-copy-copy._22-copy {
        font-size: 30px;
    }

    .text-block-33 {
        margin-top: 17px;
    }

    .text-block-13-copy {
        font-size: 16px;
        text-align: center;
    }

    .text-block-13-copy.centre.correct {
        padding-bottom: 0px;
    }

    .text-block-13-copy.info {
        width: 150%;
        margin-left: -28px;
        text-align: left;
    }

    .text-block-13-copy.sub3 {
        text-align: center;
    }

    .text-block-13-copy.info {
        text-align: left;
    }

    .heading1-2-copy {
        font-size: 14px;
    }

    .heading1-2-copy.highlight {
        margin-right: auto;
        margin-left: auto;
    }

    .link-11-copy {
        margin-left: 3px;
    }

    .text-block-13-copy-copy {
        font-size: 16px;
        text-align: center;
    }

    .text-block-13-copy-copy.centre.correct {
        padding-bottom: 0px;
    }

    .text-block-13-copy-copy.sub3 {
        text-align: center;
    }

    .bptextbox {
        font-size: 16px;
        text-align: center;
    }

    .bptextbox.centre.correct {
        padding-bottom: 0px;
    }

    .bptextbox.info {
        margin-left: 1px;
        text-align: left;
    }

    .bptextbox.sub3 {
        text-align: center;
    }

    .bptextbox.info {
        text-align: left;
    }

    .link-11-copy {
        margin-left: 3px;
    }

    .link-11-copy {
        margin-left: 3px;
    }

    .infobutton {
        /* margin-left: -60px; */
    }

    .body1-copy.c.votea {
        color: #2cbae0;
    }

    .body1-copy.highlight {
        margin-right: auto;
        margin-left: -58px;
    }

    .regbobod-copy.c.votea {
        color: #2cbae0;
    }

    .regbobod-copy.highlight {
        margin-right: auto;
        margin-left: auto;
    }

    .rewards.c.votea {
        color: #2cbae0;
    }

    .rewards.highlight {
        margin-right: auto;
        margin-left: -58px;
    }

    .daily.c.votea {
        color: #2cbae0;
    }

    .daily.highlight {
        margin-right: auto;
        margin-left: -58px;
    }

    .estreturn.c.votea {
        color: #2cbae0;
    }

    .estreturn.highlight {
        margin-right: auto;
        margin-left: -58px;
    }

    .apr.c.votea {
        color: #2cbae0;
    }

    .apr.highlight {
        margin-right: auto;
        margin-left: -58px;
    }

    .stakedamount.c.votea {
        color: #2cbae0;
    }

    .stakedamount.highlight {
        margin-right: auto;
        margin-left: -58px;
    }

    .votebps {
        margin-left: 3px;
    }

    .div-block-25-copy {
        display: none;
        overflow: visible;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        justify-items: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        grid-auto-flow: row;
        grid-auto-columns: 0.25fr;
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        -ms-grid-columns: 0.25fr 1fr;
        grid-template-columns: 0.25fr 1fr;
        -ms-grid-rows: auto auto auto auto;
        grid-template-rows: auto auto auto auto;
    }

    .pop-up-block-11 {
        font-size: 30px;
    }

    .toggle-group .tooltiptext-proxy {
        width: 400px;
        font-size: 11px;
        margin-left: -100px;
    }

    .toggle-group .tooltiptext-proxy::after {
        left: 20%;
    }

    .toggle-group .tooltiptext {
        font-size: 11px;
        margin-left: -80px;
        width: 350px;
    }

    .div-block-52 {
        font-size: 16px;
    }

    .div-block-36{
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .div-block-37{
        width: 95%;
    }

    .div-block-53{
        width: 95%;
    }

    .main-content-infor-1{
        width: 90%;
        margin-bottom: 24px;
    }

    .main-content-infor-2{
        width: 100%;
    }

    .main-content-infor-3{
        margin-top: 7px;
    }
}

#w-node-41531f2814f3-6bbeda5a {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-41531f2814fd-6bbeda5a {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-41531f281503-6bbeda5a {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-03d5282cbc68-6bbeda5a {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-03d5282cbc72-6bbeda5a {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-03d5282cbc78-6bbeda5a {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-ac2bb015537b-6bbeda5a {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-ac2bb0155385-6bbeda5a {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-ac2bb015538b-6bbeda5a {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-5b4e60a63ed8-6bbeda5a {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-5b4e60a63ee2-6bbeda5a {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-5b4e60a63ee8-6bbeda5a {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-b780a683077a-6bbeda5a {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-b780a6830787-6bbeda5a {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-b780a683078d-6bbeda5a {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

@font-face {
    font-family: 'Bebasneue';
    src: url('../fonts/BebasNeue-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Turn Off Number Input Spinners */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.w-checkbox {
    display: block;
    margin-bottom: 5px;
    padding-left: 20px;
}

.w-checkbox::before {
    content: ' ';
    display: table;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
}

.w-checkbox::after {
    content: ' ';
    display: table;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    clear: both;
}

.w-checkbox-input {
    float: left;
    margin-bottom: 0px;
    margin-left: -20px;
    margin-right: 0px;
    margin-top: 4px;
    line-height: normal;
}

.w-checkbox-input--inputType-custom {
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-color: #ccc;
    border-bottom-color: #ccc;
    border-left-color: #ccc;
    border-right-color: #ccc;
    border-top-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    width: 12px;
    height: 12px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
    background-color: #3898ec;
    border-top-color: #3898ec;
    border-bottom-color: #3898ec;
    border-left-color: #3898ec;
    border-right-color: #3898ec;
    background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
    box-shadow: 0px 0px 3px 1px #3898ec;
}

.checkbox {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    margin: 10px 10px 0px 0px;
    padding: 12px 0;
}

.checkbox>span {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
}

.checkbox>input {
    height: 17px;
    width: 17px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    border: 1px solid #C5CAE3;
    cursor: pointer;
}

.checkbox>input:checked {
    border: 1px solid #155b6e;
    background-color: #2cbae0;
}

.checkbox>input:checked+span::before {
    content: '\2714';
    text-align: center;
    position: absolute;
    margin-left: -14px;
    margin-top: -9px;
}

.checkbox>div {
    font-family: 'Open Sans', sans-serif;
    font-size: 14pt;
    font-weight: 500;
    text-align: left;
}

.checkbox>input:active {
    border: 2px solid #2F4BF1;
}

#scroll-ref::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px #fbfbff;
    background-color: #F5F5F5;
}

#scroll-ref::-webkit-scrollbar {
    width: 4px;
    background-color: #f9f9fc;
    border-radius: 2px;
}

#scroll-ref::-webkit-scrollbar-thumb {
    background-color: #cdcfd7;
    border: 1px solid #cdcfd7;
}